import get from 'lodash/get';

import {
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  SHOW_WARNING_MESSAGE,
  ON_NOTIFICATION_RESET,
} from '../action-types/ui.type';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  DEFAULT_ERROR_MESSAGE,
  NOTIFICATION_TYPE_WARNING,
} from '../constants/notification';

const initialState = {
  message: '',
  title: '',
  type: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_SUCCESS_MESSAGE: {
      const { payload: { message, title } } = action;

      return {
        ...state,
        type: NOTIFICATION_TYPE_SUCCESS,
        message,
        title,
      };
    }
    case SHOW_WARNING_MESSAGE: {
      const { payload: { message, title } } = action;

      return {
        ...state,
        type: NOTIFICATION_TYPE_WARNING,
        message,
        title,
      };
    }
    case SHOW_ERROR_MESSAGE: {
      const { payload } = action;
      const message = get(payload, 'errors[0].message', DEFAULT_ERROR_MESSAGE.message);

      return {
        ...state,
        type: NOTIFICATION_TYPE_ERROR,
        title: payload.message ? payload.message : DEFAULT_ERROR_MESSAGE.title,
        message,
      };
    }

    case ON_NOTIFICATION_RESET: {
      return initialState;
    }

    default:
      return state;
  }
};
