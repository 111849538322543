import React, { Component } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import cloneDeep from 'lodash/cloneDeep';
import { array, func, string, object, bool } from 'prop-types';

import { TableRow } from './table-row';
import {
  HEADER_HEIGHT,
  ROW_HEIGHT,
  DUMMY_ROW,
  DUMMY_ROWS_COUNT,
} from '../../constants/table-fields';
import { HeaderRow } from './header-row';
import { StickyWrapper } from './sticky-wrapper';
import { themeContext } from '../../context/ThemeContext';
import { ErrorBoundary } from '../error-boundary';
import { displayedPlantAccountsLabels } from '../../constants/plant-account-constants';
import { displayedDivisionCodesLabels } from '../../constants/division-constants';

import './Table.scss';

export const CN = 'fo-table';

export const hasValues = (filter) => {
  return Object.keys(filter).filter(key => filter[key] && filter[key].length > 0).length > 0;
};

export class Table extends Component {
  static contextType = themeContext;

  renderNoData = () => {
    return (
      <div className={`${CN}__no-data-msg`}> No data to display </div>
    );
  };

  onFilterSelect = (filter) => {
    const { selectedFilter, onUpdateSelectedFilter } = this.props;
    const appliedFilter = (selectedFilter && cloneDeep(selectedFilter)) || {};
    appliedFilter[filter.key] = filter.values;
    onUpdateSelectedFilter(appliedFilter);
  }

  renderHeader = () => {
    const {
      originalData,
      rowFields,
      tableColumns,
      isSelectedAll,
      showFilter,
      filterValues,
      selectedFilter,
      onToggleSelectAll,
    } = this.props;
    const { isDarkOn } = this.context;

    return (
      <ErrorBoundary>
        <HeaderRow
          className={tableColumns}
          isDarkOn={isDarkOn}
          readOnly={!originalData.length}
          titles={rowFields}
          showFilter={showFilter}
          filterValues={filterValues}
          onFilterSelect={this.onFilterSelect}
          withBackground
          isChecked={isSelectedAll}
          onCheckBoxClick={onToggleSelectAll}
          selectedFilter={cloneDeep(selectedFilter)}
        />
      </ErrorBoundary>
    );
  };

  renderRow = ({ index, style, data }) => {
    const {
      selectedIds,
      onRowSelect,
      isSelectedAll,
      selectedTimeZone,
      onRowClick,
      rowFields,
      tableColumns,
    } = this.props;
    const { isDarkOn } = this.context;
    const currentIndex = index - 1;
    const isChecked = isSelectedAll || selectedIds.includes(data[currentIndex].id);

    return (
      <ErrorBoundary>
        <TableRow
          className={tableColumns}
          rowFields={rowFields}
          style={style}
          data={data[currentIndex]}
          key={data[currentIndex].id}
          index={currentIndex}
          isDarkOn={isDarkOn}
          onRowSelect={onRowSelect}
          onRowClick={onRowClick}
          isChecked={isChecked}
          selectedTimeZone={selectedTimeZone}
        />
      </ErrorBoundary>
    );
  };

  getRowSize = (index) => (index ? ROW_HEIGHT : HEADER_HEIGHT);

  transformData(data) {
    const transformedData = cloneDeep(data);
    return transformedData.map((rule) => {
      rule.plantAccount = displayedPlantAccountsLabels[rule.plantAccount];
      rule.division = displayedDivisionCodesLabels[rule.division];
      return rule;
    });
  }

  render() {
    const { data } = this.props;
    const dummyData = new Array(DUMMY_ROWS_COUNT).fill(DUMMY_ROW);
    const tableData = data.length ? this.transformData(data) : dummyData;

    return (
      <div className={CN}>
        <AutoSizer>
          {
            ({ width, height }) => {
              return (
                <ErrorBoundary>
                  <StickyWrapper
                    width={width}
                    height={height}
                    itemCount={tableData.length + 1}
                    itemSize={this.getRowSize}
                    dataArray={tableData}
                    stickyIndices={[0]}
                    renderStickyRow={this.renderHeader}
                    renderNoData={(data.length) ? null : this.renderNoData}
                  >
                    {this.renderRow}
                  </StickyWrapper>
                </ErrorBoundary>
              );
            }
          }
        </AutoSizer>
      </div>
    );
  }
}

Table.propTypes = {
  data: array,
  originalData: array,
  isSelectedAll: bool,
  selectedIds: array,
  onRowSelect: func,
  onRowClick: func,
  onUpdateSelectedFilter: func,
  selectedTimeZone: object,
  tableColumns: string,
  rowFields: array,
};

Table.defaultProps = {
  data: [],
  originalData: [],
  isSelectedAll: false,
  selectedIds: [],
  selectedTimeZone: {},
};
