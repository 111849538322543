import xor from 'lodash/xor';
import cloneDeep from 'lodash/cloneDeep';

import {
  GET_RULES_SUCCESS,
  ON_RULE_TOGGLE,
  ON_RULE_EDIT,
  ON_RULE_EDIT_SUCCESS,
  ON_RULE_CREATE,
  ON_RULE_CREATE_SUCCESS,
  ON_RULE_DUPLICATE_SUCCESS,
  ON_RULE_DELETE,
  ON_RULE_FLAGS_RESET,
  ON_RULE_ALL_TOGGLE,
  ON_RULE_DELETE_CONFIRM,
  ON_RULE_UPLOAD,
  ON_RULE_UPLOAD_SUCCESS,
  ON_RULE_UPDATE_SELECTED_FILTER,
  ON_BUTTON_ENABLE,
  ON_BUTTON_DISABLE,
} from '../action-types/rules.type';
import { modes, FILTER_FIELDS } from '../constants/rules';

const initialState = {
  data: [],
  originalData: [],
  filterValues: {},
  selectedFilter: {},
  selectedIds: [],
  selectedRow: '',
  isSelectedAll: false,
  showEdit: false,
  mode: null,
  disableButtons: [],
};

const includedValues = (values, allValues) => {
  if (!values) {
    return [];
  }
  return values.filter(val => allValues && allValues.includes(val));
};

const validateAndGetFilters = (selectedFilter, filterValues) => {
  const newFilter = {};
  const keys = Object.keys(selectedFilter);
  keys.forEach((key) => {
    const covered = includedValues(selectedFilter[key], filterValues[key]);
    if (covered && covered.length > 0) {
      newFilter[key] = covered;
    }
  });
  return newFilter;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_RULES_SUCCESS: {
      const { payload } = action;
      const data = mapData(payload);
      const { selectedFilter } = state;
      const filterValues = getFilterValues(data);
      const newSelectedFilter = validateAndGetFilters(selectedFilter, filterValues);
      return {
        ...state,
        data: getFilteredData(data, newSelectedFilter),
        filterValues: filterValues,
        selectedFilter: newSelectedFilter,
        originalData: data,
      };
    }

    case ON_BUTTON_DISABLE: {
      const { buttonType } = action;
      return {
        ...state,
        disableButtons: [...new Set([...state.disableButtons, buttonType])],
      };
    }
    case ON_BUTTON_ENABLE: {
      const { buttonType } = action;
      return {
        ...state,
        disableButtons: state.disableButtons.filter(type => type !== buttonType),
      };
    }
    case ON_RULE_UPDATE_SELECTED_FILTER: {
      const { selectedFilter } = action;
      return {
        ...state,
        selectedFilter,
        data: getFilteredData(state.originalData, selectedFilter),
      };
    }

    case ON_RULE_TOGGLE: {
      const { selectedIds, data } = state;
      const { payload } = action;
      const updatedSelectedIds = xor([...selectedIds], [payload]);

      return {
        ...state,
        selectedIds: updatedSelectedIds,
        isSelectedAll: updatedSelectedIds.length === data.length,
      };
    }

    case ON_RULE_EDIT: {
      return {
        ...state,
        mode: modes.EDIT,
      };
    }

    case ON_RULE_EDIT_SUCCESS: {
      const { rules } = action;
      const successIds = rules.filter(rule => rule.status === 200).map(rule => rule.data.ruleId);
      const filteredData = cloneDeep(state.data).filter(data => !successIds.includes(data.id));
      const transformeddata = mapDataforEdit(rules);
      const updatedData = transformeddata.concat(filteredData);
      return {
        ...state,
        data: updatedData,
        filterValues: getFilterValues(updatedData),
        mode: null,
        selectedIds: [],
        isSelectedAll: false,
      };
    }

    case ON_RULE_CREATE: {
      return {
        ...state,
        mode: modes.ADD,
      };
    }

    case ON_RULE_CREATE_SUCCESS: {
      const { rule } = action;
      const newUD = mapData({ 'objects': rule });
      const updatedData = [...newUD, ...cloneDeep(state.data)];
      const updatedOriginalData = [...newUD, ...cloneDeep(state.originalData)];
      const { selectedFilter } = state;
      const filterValues = getFilterValues(updatedOriginalData);
      const newSelectedFilter = validateAndGetFilters(selectedFilter, filterValues);

      return {
        ...state,
        data: getFilteredData(updatedData, newSelectedFilter),
        filterValues: filterValues,
        selectedFilter: newSelectedFilter,
        originalData: updatedOriginalData,
        mode: null,
      };
    }

    case ON_RULE_DUPLICATE_SUCCESS: {
      return {
        ...state,
      };
    }

    case ON_RULE_DELETE: {
      return {
        ...state,
        mode: modes.DELETE,
      };
    }

    case ON_RULE_DELETE_CONFIRM: {
      const { data } = action;
      const success = data.filter(d => d.status === 204).map(d => d.id);

      const newData = cloneDeep(state.data);
      const updatedData = newData.filter(d => !success.includes(d.id));

      return {
        ...state,
        data: updatedData,
        filterValues: getFilterValues(updatedData),
        selectedIds: [],
        isSelectedAll: false,
        mode: null,
      };
    }

    case ON_RULE_FLAGS_RESET: {
      return {
        ...state,
        mode: null,
      };
    }

    case ON_RULE_ALL_TOGGLE: {
      const { data, isSelectedAll } = state;
      return {
        ...state,
        selectedIds: isSelectedAll ? [] : data.map(elem => elem.id),
        isSelectedAll: !isSelectedAll,
      };
    }

    case ON_RULE_UPLOAD: {
      return {
        ...state,
        mode: modes.UPLOAD,
      };
    }

    case ON_RULE_UPLOAD_SUCCESS: {
      const { rules } = action;

      return {
        ...state,
        data: mapData(rules),
        filterValues: getFilterValues(rules),
        mode: null,
        selectedIds: [],
        selectedRow: '',
        isSelectedAll: false,
      };
    }

    default:
      return state;
  }
};

const mapData = (list) => {
  if (!list || !list.objects) {
    return [];
  }
  return list.objects.map((item) => {

    const { endOffsetDays, startOffsetDays, launchCode } = item.rule;

    return {
      'id': item.ruleId,
      ...item.rule,
      startOffsetDays: Number(startOffsetDays),
      endOffsetDays: Number(endOffsetDays),
      launchCode: launchCode === true ? 'Y' : 'N',
    };
  });
};

const mapDataforEdit = (list) => {
  if (!list) {
    return [];
  }
  return list.map((item) => {

    const { endOffsetDays, startOffsetDays, launchCode } = item.data.rule;

    return {
      'id': item.data.ruleId,
      ...item.data.rule,
      startOffsetDays: Number(startOffsetDays),
      endOffsetDays: Number(endOffsetDays),
      launchCode: launchCode === true ? 'Y' : 'N',
    };
  });
};

const getFilterValues = (data) => {
  const filter = {};
  FILTER_FIELDS.forEach(col => {
    const values = Array.from(new Set(data.map(elem => elem[col]))).sort();
    filter[col] = values;
  });
  return filter;
};


const getFilteredData = (data, selectedFilter) => {
  return data.filter(elem => Object.keys(elem).every(key => !selectedFilter[key] || selectedFilter[key].length === 0
    || selectedFilter[key].filter(val => val === elem[key]).length > 0));
};
