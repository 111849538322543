import React, { Fragment, memo } from 'react';
import { func, number, bool } from 'prop-types';
import get from 'lodash/get';

import { WarningMessage } from '../warning-message';
import { Popup } from '../popup';
import { Button, BUTTON_STYLES } from '../button';

import './DeleteModal.scss';

export const CN = 'fo-delete-modal';

const APPLY_LABEL = 'Yes, delete';
const CANCEL_LABEL = 'No, wait';
const LABEL = 'label';
const MSG = 'msg';
const QUESTION_TEXT = 'questionText';

const content = (item, affectedCount) => {
  const messages = {
    [LABEL]: 'Delete rules',
    [QUESTION_TEXT]: (
      <Fragment>
        You are about to delete <span className={`${CN}__count`}>{affectedCount}</span> rule(s), that has been setup previously.
            Are you sure you want to delete it?
      </Fragment>
    ),
  };

  return get(messages, item, '');
};

export const DeleteModal = memo(({
  onApply,
  affectedCount,
  isOpen,
  onClose,
}) => {
  affectedCount = affectedCount || 0;

  const renderHeading = () => <div>{content(LABEL)}</div>;
  const renderContent = () => <div>{content(MSG)}</div>;

  const onApplyHandler = () => {
    onApply && onApply();
  };

  return (
    <Popup
      heading={renderHeading()}
      isOpen={isOpen}
      onClose={onClose}
      onApply={onApply}
      onCancel={onClose}
      cancelLabel={CANCEL_LABEL}
      applyLabel={APPLY_LABEL}
      className={CN}
    >
      <div className={`${CN}__message`}>{renderContent()}</div>
      <WarningMessage
        msg={content(QUESTION_TEXT, affectedCount)}
        className={`${CN}__question`}
      />

      <div className={`${CN}__actions`}>
        <Button
          label={CANCEL_LABEL}
          className={`${CN}__cancel`}
          onClickHandler={onClose}
          style={BUTTON_STYLES.primary}
        />
        <Button
          label={APPLY_LABEL}
          className={`${CN}__apply`}
          onClickHandler={onApplyHandler}
          style={BUTTON_STYLES.primary}
        />
      </div>
    </Popup>
  );
});

DeleteModal.propTypes = {
  onClose: func.isRequired,
  onApply: func.isRequired,
  affectedCount: number,
  isOpen: bool,
};

DeleteModal.defaultProps = {
  affectedCount: 1,
};
