import { number, object } from 'prop-types';
import React from 'react';

import { ErrorBoundary } from '../../error-boundary';

export const ItemWrapper = (props) => {
  const { data, index, style } = props;
  const { ItemRenderer, stickyIndices, dataArray } = data;

  if (stickyIndices && stickyIndices.includes(index)) {
    return null;
  }

  return (
    <ErrorBoundary>
      <ItemRenderer
        index={index}
        style={style}
        data={dataArray}
      />
    </ErrorBoundary>
  );
};

ItemWrapper.propTypes = {
  data: object,
  index: number,
  style: object,
};
