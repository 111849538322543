import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import { ThemeProviderWrapper } from '../context/ThemeContext';
import { VERTICAL_POS, HORIZONTAL_POS } from '../constants/notification';
import App from './App';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ThemeProviderWrapper>
          <ConnectedRouter history={history}>
            <SnackbarProvider
              style={{ maxWidth: 'maxContent', right: '20px', left: 'unset' }}
              anchorOrigin={{
                vertical: VERTICAL_POS,
                horizontal: HORIZONTAL_POS,
              }}
            >
              <App />
            </SnackbarProvider>
          </ConnectedRouter>
        </ThemeProviderWrapper>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
