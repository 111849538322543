import React from 'react';
import cx from 'classnames';
import { bool, func, string } from 'prop-types';

import './Checkbox.scss';

export const CN = 'fo-checkbox';

export const Checkbox = (props) => {
  const { checked, label, onCheck, className } = props;

  return (
    <div
      className={cx(CN, className)}
      onClick={onCheck}
    >
      <input
        className={`${CN}__input`}
        type="checkbox"
        checked={checked}
        readOnly
      />
      <label className={`${CN}__label`}>{label}</label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: bool,
  label: string,
  onCheck: func,
  className: string,
};

Checkbox.defaultProps = {
  checked: false,
  label: '',
  className: '',
};
