import React, { Component } from 'react';
import cx from 'classnames';
import { func, bool, string } from 'prop-types';
import { TooltipWrapper } from '../TooltipWrapper';
import { SvgIcon } from '../svg-icon/SvgIcon';

import './CustomButton.scss';
import '../../assets/icons/download.svg';

import '../../assets/icons/upload.svg';


export const CN = 'custom-btn';

export class CustomButton extends Component {

  onClick = () => {
    const { onClickHandler } = this.props;

    onClickHandler && onClickHandler();
  };


  toolTipMsg = () => {
    const { disabled, disableToolTip, enableToolTip } = this.props;
    return disabled ? disableToolTip : enableToolTip;
  }

  render() {
    const { disabled, title, name } = this.props;

    return (
      <TooltipWrapper val={this.toolTipMsg() || ''}>
        <div
          className={cx(CN, { [`${CN}--disabled `]: disabled })}
          onClick={disabled ? () => {} : this.onClick}
        >
          {title}
          <SvgIcon
            className={cx(`${CN}__custom-icon`, {
              [`${CN}__custom-icon--disabled `]: disabled,
            })}
            name={name}
          />
        </div>
      </TooltipWrapper>
    );
  }
}

CustomButton.propTypes = {
  disabled: bool,
  disableToolTip: string,
  enableToolTip: string,
  onClickHandler: func.isRequired,
};

CustomButton.defaultProps = {
  disabled: false,
  disableToolTip: '',
  enableToolTip: '',
};
