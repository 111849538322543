import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { Popup } from '../popup';
import { EditableTableRow } from '../table/editable-table-row';
import { defaultDataStructure, rulesTableFields, modes, PLUS_START_DAYS, PLUS_END_DATES, LAUNCH_CODE, FILTER_FIELDS, COMMENT, UPDATE, SOLD_TO, SHIP_TO, CITY, STATE, SHIP_TO_NAME, CHANNEL, optionalFields } from '../../constants/rules';
import { HeaderRow } from '../table/header-row';
import { TextArea } from '../textarea';
import { Button, BUTTON_STYLES } from '../button';
import { ConfirmationToast } from '../confirmation-toast';
import { displayedDivisionCodes } from '../../constants/division-constants';
import './AddEditModal.scss';

const CN = 'add-edit-modal';

export class AddEditModal extends Component {

  constructor(props) {
    super(props);

    this.originalRows = this.prepareData();

    const { mode, selectedIds, data } = props;
    const editRow = (mode === modes.EDIT && selectedIds.length === 1) ? data.find(item => item.id === selectedIds[0]) : null;
    const comment = editRow ? editRow.comment : '';

    this.state = {
      editableRows: [editRow || this.prepareDefaultData()],
      comment: comment,
      showConfirm: false,
      errorRows: [this.prepareDefaultData()],
    };
  }

  prepareData = () => {
    const { data, selectedIds, mode, selectedRow, isSelectedAll } = this.props;

    if (mode === modes.ADD) return [];

    // edit mode selected all
    if (isSelectedAll) {
      return cloneDeep(data);
    }
    // edit mode
    const rowsBase = selectedIds.length ? selectedIds : [selectedRow];
    return rowsBase.map((id) => {
      const dataRule = data.find(item => item.id === id);
      return {
        ...dataRule,
      };
    });
  };

  prepareDefaultData = () => {
    const defaultData = Object.keys(defaultDataStructure).reduce((acc, key) => {
      acc[key] = defaultDataStructure[key].value;
      return acc;
    }, {});
    // randomly generated rule name
    defaultData.name = 'ui-delivery-window-' + uuidv4();
    return defaultData;
  };

  onCommentChange = (comment) => {
    this.setState({ comment });
  };

  onRowChange = (changedData, errorData) => {
    const { editableRows, errorRows } = this.state;
    const { id } = changedData;

    const rowIndex = editableRows.findIndex(item => item.id === id);

    if (rowIndex === -1) {
      console.warn('Rule row was not found. Smth went wrong with editing.'); //eslint-disable-line
      return;
    }

    editableRows[rowIndex] = changedData;
    errorRows[rowIndex] = errorData;
    this.setState({
      editableRows, errorRows,
    });
  };

  onApplyHandler = () => {
    this.setState({ showConfirm: true });
  };

  isDisabled = () => {
    const { mode } = this.props;
    const { editableRows: [row], errorRows } = this.state;
    if (errorRows[0][SOLD_TO] || errorRows[0][SHIP_TO]) return true;
    if (mode === modes.EDIT) return false;
    if (mode === modes.ADD) {
      return Object.keys(row).some(key => {
        if (defaultDataStructure[key].type === 'text' && !optionalFields.includes(key)) {
          return !row[key].trim();
        }

        return false;
      });
    }
  };

  onConfirmClose = () => {
    this.setState({ showConfirm: false });
  };

  isEqual = (data1, data2) => FILTER_FIELDS.filter(fil => String(data1[fil]) === String(data2[fil])).length === FILTER_FIELDS.length;


  onConfirm = () => {
    const { editableRows, comment } = this.state;
    const { actions: { updateRules, createNewRule, duplicateRule, disableButton }, mode, onClose, data } = this.props;

    this.setState({ showConfirm: false });

    const dataNew = editableRows[0];

    if (mode === modes.ADD) {
      if (comment) {
        dataNew.comment = comment;
      }
      const existing = data.filter(elem => this.isEqual(elem, dataNew)).length > 0;
      if (existing) {
        duplicateRule(dataNew);
        return;
      }

      if (dataNew.launchCode) {
        if (dataNew.launchCode === 'Y') {
          dataNew[LAUNCH_CODE] = true;
        } else {
          dataNew[LAUNCH_CODE] = false;
        }
      }

      if (dataNew.division === 'All') {
        const dataForAll = [];
        displayedDivisionCodes.filter(el => el !== 'All').forEach(div => {
          const element = { rule: { ...dataNew, 'division': div }, 'userDetails': this.userDetails() };
          dataForAll.push(element);
        });
        createNewRule(dataForAll);
      } else {
        const dataForOne = [];
        const eleForOne = { rule: { ...dataNew }, 'userDetails': this.userDetails() };
        dataForOne.push(eleForOne);
        createNewRule(dataForOne);
      }
    }

    if (mode === modes.EDIT) {
      disableButton(UPDATE);
      const dataForRequest = this.originalRows.map(row => {
        if (comment) {
          row[COMMENT] = comment;
        }
        row[PLUS_START_DAYS] = dataNew[PLUS_START_DAYS];
        row[PLUS_END_DATES] = dataNew[PLUS_END_DATES];
        row[CITY] = dataNew[CITY];
        row[STATE] = dataNew[STATE];
        row[SHIP_TO_NAME] = dataNew[SHIP_TO_NAME];
        row[CHANNEL] = dataNew[CHANNEL];

        const rule = {};
        rule[PLUS_START_DAYS] = row[PLUS_START_DAYS];
        rule[PLUS_END_DATES] = row[PLUS_END_DATES];
        rule[COMMENT] = row[COMMENT];
        rule[CITY] = row[CITY];
        rule[STATE] = row[STATE];
        rule[SHIP_TO_NAME] = row[SHIP_TO_NAME];
        rule[CHANNEL] = row[CHANNEL];

        return { 'ruleId': row.id, rule, 'userDetails': this.userDetails() };
      });
      updateRules(dataForRequest);
    }

    onClose && onClose();
  };

  userDetails = () => {
    const { userInfo } = this.props;
    return {
      updatedBy: userInfo ? userInfo.name : '',
    };
  }

  renderHeading() {
    const { mode } = this.props;

    const label = mode === modes.EDIT ? 'Edit rule(s)' : 'Add rule(s)';

    return (
      <div className={`${CN}__heading`}>{label}</div>
    );
  }

  renderHeaderRow = () => {
    const { readOnly } = this.props;

    return (
      <div className={`${CN}__header-row`}>
        <HeaderRow
          readOnly={readOnly}
          titles={rulesTableFields}
          hidePlaceholder
          className={`${CN}__table__header-row`}
          withBackground
        />
      </div>
    );
  };

  renderTable = () => {
    const { editableRows: [editableRow], errorRows } = this.state;
    const { mode } = this.props;

    return (
      <div className={`${CN}__table`}>

        {this.renderHeaderRow()}
        <EditableTableRow
          key={editableRow.id || 0}
          data={editableRow}
          index={0}
          rowFields={rulesTableFields}
          onChange={this.onRowChange}
          mode={mode}
          errorRows={errorRows[editableRow.id || 0]}
        />
      </div>
    );
  };

  renderActionConfirmation = () => {
    const { showConfirm } = this.state;

    if (!showConfirm) return null;

    const counter = this.originalRows.length;

    return (
      <ConfirmationToast
        counter={counter}
        onConfirm={this.onConfirm}
        onCancel={this.onConfirmClose}
      />
    );
  };

  render() {
    const { mode, onClose, onApply, data, selectedIds, isSelectedAll } = this.props;
    const { comment, showConfirm } = this.state;

    return (
      <Popup
        heading={this.renderHeading()}
        isOpen={!!mode}
        onClose={onClose}
        onApply={onApply}
        onCancel={onClose}
        cancelLabel="Cancel"
        applyLabel="Submit"
        className={CN}
      >
        {
          this.renderTable()
        }

        {(mode === modes.ADD || selectedIds.length === 1) && (
          <div className={`${CN}__block ${CN}__block__text-area`}>
            <div className={`${CN}__label`}>Comment [Optional]</div>
            <TextArea
              value={comment}
              onChange={this.onCommentChange}
              rows={3}
              placeholder="Your comment"
              maxLength={240}
            />
          </div>
        )}

        {this.renderActionConfirmation()}
        {
          !showConfirm && (
            <div className={`${CN}__actions`}>
              <Button
                label="Cancel"
                className={`${CN}__cancel`}
                onClickHandler={onClose}
                style={BUTTON_STYLES.primary}
              />
              <Button
                label={mode === modes.EDIT ? `Submit changes to ${(isSelectedAll ? data.length : selectedIds.length)} rule(s)` : 'Add new rule'}
                className={`${CN}__apply`}
                disabled={this.isDisabled()}
                onClickHandler={mode === modes.EDIT ? this.onApplyHandler : this.onConfirm}
                style={BUTTON_STYLES.primary}
              />
            </div>
          )
        }
      </Popup>
    );
  }
}
