import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { DeliveryWindow } from './DeliveryWindow';
import {
  getRulesList,
  downloadRules,
  deleteRules,
  onItemSelect,
  onItemToggle,
  onEdit,
  onResetFlags,
  onToggleSelectAll,
  onCreate,
  onDelete,
  onUpload,
  uploadRules,
  onUpdateSelectedFilter,
  disableButton,
} from '../../actions/rules.action';

export const mapStateToProps = (state) => {
  const {
    ui: { loadersList },
    router: { location: { search } },
    rules: {
      data,
      selectedIds,
      filterValues,
      selectedFilter,
      isSelectedAll,
      selectedRow,
      originalData,
      showEdit,
      showDelete,
      showUpload,
      mode,
      disableButtons,
    },
  } = state;
  return {
    loadersList,
    search,
    data,
    filterValues,
    selectedFilter,
    selectedIds,
    isSelectedAll,
    selectedRow,
    originalData,
    showEdit,
    showDelete,
    showUpload,
    mode,
    disableButtons,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      getRulesList,
      onItemSelect,
      onItemToggle,
      onEdit,
      onResetFlags,
      onToggleSelectAll,
      deleteRules,
      downloadRules,
      uploadRules,
      onCreate,
      onDelete,
      onUpload,
      onUpdateSelectedFilter,
      disableButton,
    }, dispatch),
  };
};

export const DeliveryWindowPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(DeliveryWindow);
