import React, { createContext, forwardRef } from 'react';
import { node, arrayOf, oneOfType, func, array } from 'prop-types';

import { VariableSizeList as List } from 'react-window';
import { ItemWrapper } from './ItemWrapper';

const StickyListContext = createContext();
StickyListContext.displayName = 'StickyListContext';

/** innerElementType
 * consumer for specific fields to render
 * if you need more then one sticky row you can add loop through stickyIndices
 */

export const innerElementType = forwardRef(({ children, ...rest }, ref) => (
  <StickyListContext.Consumer>
    {({ /* stickyIndices, */ renderStickyRow, renderNoData }) => (
      <div ref={ref} {...rest}>
        {renderStickyRow()}
        {renderNoData && renderNoData()}
        {children}
      </div>
    )}
  </StickyListContext.Consumer>
));

innerElementType.propTypes = {
  renderStickyRow: func,
};

export const StickyWrapper = ({
  children,
  stickyIndices,
  renderStickyRow,
  dataArray,
  renderNoData,
  ...rest
}) => (
  <StickyListContext.Provider value={{ ItemRenderer: children, stickyIndices, renderStickyRow, renderNoData }}>
    <List
      itemData={{ ItemRenderer: children, stickyIndices, dataArray }}
      innerElementType={innerElementType}
      {...rest}
    >
      {ItemWrapper}
    </List>
  </StickyListContext.Provider>
);

StickyWrapper.propTypes = {
  stickyIndices: array,
  renderStickyRow: func,
  dataArray: array,
  children: oneOfType([
    arrayOf(node),
    node,
    func,
  ]).isRequired,
  renderNoData: func,
};
StickyWrapper.defaultProps = {
  stickyIndices: [],
  renderStickyRow: () => null,
  dataArray: [],
};
