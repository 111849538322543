import React, { PureComponent } from 'react';
import { object, number, func, bool, array, string } from 'prop-types';
import cx from 'classnames';
import isNil from 'lodash/isNil';
import { Checkbox } from '../../checkbox/Checkbox';
import { TooltipWrapper } from '../../TooltipWrapper';

import '../../../assets/icons/info.svg';
import './TableRow.scss';

export const CN = 'fo-table-row';

const ROW_ORDER = ['even', 'odd'];

const getFieldContent = (field, data) => {
  const formattedContent = data[field];

  const renderContent = () => {
    return (
      <div
        className={`${CN}__value`}
      >
        {isNil(formattedContent) ? <span className={`${CN}--no-data`}>N/A</span> : formattedContent}
      </div>
    );
  };
  // no need in tooltip if val is falsy
  return (!formattedContent) ? renderContent() : (
    <TooltipWrapper val={formattedContent}>
      {renderContent()}
    </TooltipWrapper>
  );
};

export class TableRow extends PureComponent {

  onRowClick = () => {
    const { onRowClick, data: { id } } = this.props;

    onRowClick && onRowClick(id);
  };

  onCheckBoxClick = (e) => {
    e.stopPropagation();

    const { onRowSelect, data: { id } } = this.props;
    onRowSelect && onRowSelect(id, { checkbox: true });
  };

  render() {
    const {
      data,
      data: { soldTo },
      style,
      index,
      isDarkOn,
      isChecked,
      loading,
      disabledHighlighting,
      hasError,
      rowFields,
      hideCheckbox,
      className,
      onRowClick,
    } = this.props;

    return (
      <div
        onClick={this.onRowClick}
        className={cx(CN, className, {
          [`${CN}--no-events`]: !soldTo,
          [`${CN}--selected`]: !disabledHighlighting && isChecked && !loading,
          [`${CN}--has-error`]: hasError,
          [`${CN}--is-clickable`]: !!onRowClick,
          [`${CN}--without-checkbox`]: hideCheckbox,
        })}
        style={style}
      >
        {
          !hideCheckbox && (
            <Checkbox
              className={cx(
                `${CN}__checkbox-wrapper`, {
                  [`${CN}__checkbox-wrapper--dark-theme`]: isDarkOn,
                  [`${CN}__hidden`]: loading || !data.plantAccount,
                },
              )}
              checked={isChecked}
              onCheck={this.onCheckBoxClick}
            />
          )
        }

        {
          rowFields.map((field) => (
            <div
              key={field}
              className={cx(
                `${CN}__item`,
                `${CN}__item__${field}`,
                `${CN}__item--${ROW_ORDER[index % 2]}`,
                {
                  [`${CN}__item--has-error`]: hasError,
                  [`${CN}__item--unchecked`]: disabledHighlighting && !isChecked,
                },
              )}
            >
              {getFieldContent(field, data)}
            </div>
          ))
        }
      </div>
    );
  }
}

TableRow.propTypes = {
  data: object,
  style: object,
  index: number,
  isDarkOn: bool,
  onRowSelect: func,
  onRowClick: func,
  loading: bool,
  isChecked: bool,
  disabledHighlighting: bool,
  hasError: bool,
  rowFields: array,
  hideCheckbox: bool,
  className: string,
};

TableRow.defaultProps = {
  isDarkOn: false,
  loading: false,
  isChecked: false,
  disabledHighlighting: false,
  hasError: false,
  rowFields: [],
  hideCheckbox: false,
  className: '',
};
