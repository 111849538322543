/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import { string, func, bool, oneOf } from 'prop-types';
import { TooltipWrapper } from '../TooltipWrapper';
import './Button.scss';

export const CN = 'fo-button';
export const BUTTON_STYLES = {
  primary: 'primary',
  secondary: 'secondary',
  tabPrimary: 'tab-primary',
  tabSecondary: 'tab-secondary',
};

export const BUTTON_TYPES = {
  button: 'button',
  submit: 'submit',
};

export const BUTTON_SIZES = {
  small: 'small',
  regular: 'regular',
};

export class Button extends Component {

  onClick = () => {
    const { onClickHandler } = this.props;

    onClickHandler && onClickHandler();
  };

  render() {
    const { className, style, type = BUTTON_TYPES.button, disabled, label, size, toolTip } = this.props;

    return (// eslint-disable-next-line
      <TooltipWrapper val={toolTip || ''}>
        <div>
          <button
            className={`${CN} ${CN}--${style} ${CN}--${size} ${className}`}
            onClick={this.onClick}
            disabled={disabled}
            type={type}
          >
            {label}
          </button>
        </div>
      </TooltipWrapper>
    );
  }
}

Button.propTypes = {
  className: string,
  style: oneOf(Object.values(BUTTON_STYLES)).isRequired,
  type: oneOf(Object.values(BUTTON_TYPES)),
  size: oneOf(Object.values(BUTTON_SIZES)),
  disabled: bool,
  label: string.isRequired,
  onClickHandler: func.isRequired,
};

Button.defaultProps = {
  className: '',
  type: BUTTON_TYPES.button,
  disabled: false,
  size: BUTTON_SIZES.regular,
};
