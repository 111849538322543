import React, { memo } from 'react';
import cx from 'classnames';

import './AnimatedLoader.scss';

export const CN = 'fo-animated-loader';

export const AnimatedLoader = memo(({ classNames = '', fullPageView }) => (
  <div
    className={cx(CN, classNames, {
      [`${CN}__full-page-view `]: fullPageView,
    })}
  >
    <div />
    <div />
    <div />
    <div />
  </div>
));
