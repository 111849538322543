export const VERTICAL_POS = 'bottom';
export const HORIZONTAL_POS = 'right';
export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_WARNING = 'warn';

export const AUTO_HIDE_DELAYS = {
  error: 5 * 1000,
  success: 5 * 1000,
};

export const DEFAULT_ERROR_MESSAGE = {
  title: 'Something Went Wrong',
  message: 'There was a server error, try again later',
};

export const SUCCESS_MESSAGES = {
  rulesUploaded: {
    title: 'Success',
    message: 'Rules from file were successfully uploaded!',
  },
  rulesUpdated: {
    title: 'Success',
    message: 'Rules were updated  successfully!',
  },
  ruleCreated: {
    title: 'Success',
    message: 'Rules were created successfully!',
  },
  rulesDeleted: {
    title: 'Success',
    message: 'Rules were deleted successfully!',
  },
};

export const WARNING_MESSAGES = {
  rulesUploaded: {
    title: 'Warnings',
    message: 'Please check results for errors',
  },
};

export const DUPLICATE_MESSAGE = {
  title: 'Duplicate',
  message: 'Rule Entry is Duplicate',
};
