export class RulesService {
  constructor(adapter, { api: { rules: { path, endpoints } } }) {
    this._adapter = adapter;
    this._rulesList = `${path}${endpoints.list}`;
    this._downloadRules = `${path}${endpoints.download}`;
    this._deleteRules = `${path}${endpoints.delete}`;
    this._createRule = `${path}${endpoints.create}`;
    this._updateRules = `${path}${endpoints.update}`;
    this._uploadRules = `${path}${endpoints.upload}`;
  }

  getRulesList = (params) => {
    return this._adapter.get(this._rulesList, params).then(({ data }) => {
      return data;
    });
  };

  downloadRules = (mediaType, ids) => {
    const params = { ...mediaType, ids: ids || null };
    return this._adapter.getFile(this._downloadRules, params).then(({ data }) => {
      return data;
    });
  };

  deleteRules = (params) => {
    return this._adapter.bulkRequestor(params, (data) => this._adapter.delete(this._deleteRules, data));
  };

  updateRules = (rules) => {
    const arrayPayload = rules.map(el => ({ el, 'params': { 'id': el.ruleId } }));
    return this._adapter.bulkRequestor(arrayPayload, data => this._adapter.patch(this._updateRules, data.el, data.params));
  };

  createRule = (rule) => {
    return this._adapter.post(this._createRule, rule).then(({ data }) => {
      return data;
    });
  };

  uploadRules = (file, successAction, failureAction) => {
    this._adapter.uploadfile(this._uploadRules, file).then((data) => {
      successAction(data);
    }).catch((e) => {
      failureAction(e);
    });
  };

}
