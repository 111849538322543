import React, { Component } from 'react';
import { bool, func, node, oneOfType, string, arrayOf, object } from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import DialogTitle from '@material-ui/core/DialogTitle';

const TRANSITION_DURATION = 1500;
const CN = 'fo-popup';
const Transition = (props) => {
  return (
    <Slide
      timeout={{ enter: TRANSITION_DURATION, exit: TRANSITION_DURATION }}
      direction="up"
      {...props}
    />
  );
};

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '',
      },
    },
  },
});

const DraggableComponent = props => {
  return (
    <Draggable handle="#draggable-dialog">
      <Paper {...props} />
    </Draggable>
  );
};

export class Modal extends Component {
  render() {
    const { isOpen, maxWidth, handleModalToggle, children, ariaLabelledby, ariaDescribedby, fullWidth } = this.props;

    return (
      <MuiThemeProvider theme={theme}>
        <Dialog
          open={!!isOpen}
          TransitionComponent={Transition}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          onClose={handleModalToggle}
          aria-labelledby={ariaLabelledby}
          aria-describedby={ariaDescribedby}
          scroll="paper"
          PaperComponent={DraggableComponent}
        >
          {children}

          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog">
            <div className={`${CN}__label`}>Drag to see all existing rules</div>
          </DialogTitle>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

Modal.propTypes = {
  isOpen: oneOfType([bool, string, object]).isRequired,
  maxWidth: oneOfType([bool, string]),
  handleModalToggle: func.isRequired,
  ariaLabelledby: string,
  ariaDescribedby: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
  fullWidth: bool,
};

Modal.defaultProps = {
  ariaLabelledby: 'alert-dialog-slide-title',
  ariaDescribedby: 'alert-dialog-slide-description',
  maxWidth: false,
  fullWidth: false,
};
