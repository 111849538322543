import React, { Component, Fragment } from 'react';
import { string, node, arrayOf, oneOfType } from 'prop-types';

export class ErrorBoundary extends Component {

  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  renderError = () => {
    const { message } = this.props;

    if (!message) return null;

    return (
      <div>{message}</div>
    );
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) return this.renderError();

    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
}

ErrorBoundary.propTypes = {
  message: oneOfType([
    arrayOf(node),
    node,
    string,
  ]),
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

ErrorBoundary.defaultProps = {
  message: '',
  children: null,
};
