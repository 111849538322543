import React, { memo } from 'react';
import Zoom from '@material-ui/core/Zoom';

import './ConfirmationToast.scss';

const transitionDuration = 200;

export const CN = 'fo-file-upload-confirmation-toast';

export const FileUploadConfirmationToast = memo(({ file, onCancel, onConfirm }) => (
  <Zoom in timeout={transitionDuration}>
    <div className={CN}>
      <div
        className={`${CN}__message`}
      >
        You are about to upload new rules from file <span className={`${CN}__file-name`}>{file.name}</span>. It will override offset values if records are same and if records are not same it will create a new rule.
        Are you sure you want to upload new rules?
      </div>
      <div className={`${CN}-actions`}>
        <div onClick={onConfirm} className={`${CN}__action`}>Yes</div>
        <div className={`${CN}__divider`} />
        <div onClick={onCancel} className={`${CN}__action`}>No</div>
      </div>
    </div>
  </Zoom>
));
