import React, { Component } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

import './SvgIcon.scss';

const CN = 'fo-svg-icon';

export class SvgIcon extends Component {
  onClick = () => {
    const { onClick } = this.props;
    onClick && onClick();
  };

  render() {
    const { name, className, onClick } = this.props;
    const cn = classNames(CN, `${CN}__${name}`, className, {
      [`${CN}--clickable`]: !!onClick,
    });

    return (
      <svg className={cn} onClick={this.onClick}>
        <use xlinkHref={`#${name}`} />
      </svg>
    );
  }
}

SvgIcon.propTypes = {
  name: string,
  className: string,
  onClick: func,
};
