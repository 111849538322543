import { batch } from 'react-redux';
import {
  GET_RULES_SUCCESS,
  ON_RULE_TOGGLE,
  ON_RULE_SELECT,
  ON_RULE_EDIT,
  ON_RULE_EDIT_SUCCESS,
  ON_RULE_DELETE,
  ON_RULE_FLAGS_RESET,
  ON_RULE_ALL_TOGGLE,
  ON_RULE_CREATE,
  ON_RULE_CREATE_SUCCESS,
  ON_RULE_DUPLICATE_SUCCESS,
  ON_RULE_DELETE_CONFIRM,
  ON_RULE_UPLOAD,
  ON_RULE_UPLOAD_SUCCESS,
  ON_RULE_UPDATE_SELECTED_FILTER,
  ON_BUTTON_DISABLE,
  ON_BUTTON_ENABLE,
} from '../action-types/rules.type';
import { showMessage, addLoader, removeLoader } from './ui.action';
import services from '../services';
import { NOTIFICATION_TYPE_ERROR, SUCCESS_MESSAGES, DUPLICATE_MESSAGE, WARNING_MESSAGES, NOTIFICATION_TYPE_WARNING } from '../constants/notification';
import { RULES_LIST_LOADER } from '../constants/loaders';
import { DELETE, UPDATE, UPLOAD } from '../constants/rules';

const { rulesService } = services;

export const getRulesSuccess = list => ({ type: GET_RULES_SUCCESS, payload: list });

export const disableButton = buttonType => ({ type: ON_BUTTON_DISABLE, buttonType });

export const enableButton = buttonType => ({ type: ON_BUTTON_ENABLE, buttonType });

export const getRulesList = (params) => {
  return (dispatch) => {
    dispatch(addLoader(RULES_LIST_LOADER));

    return rulesService.getRulesList(params)
      .then((data) => {
        batch(() => {
          dispatch(getRulesSuccess(data));
          dispatch(removeLoader(RULES_LIST_LOADER));
        });
      })
      .catch((errorData) => {
        const { error: { response: { data } = {} } } = errorData;

        batch(() => {
          // dispatch(resetList());
          dispatch(removeLoader(RULES_LIST_LOADER));
          dispatch(showMessage(data, NOTIFICATION_TYPE_ERROR));
        });
      });
  };
};

export const onToggleSelectAll = () => ({ type: ON_RULE_ALL_TOGGLE });

export const onItemToggle = payload => ({ type: ON_RULE_TOGGLE, payload });

export const onEdit = () => ({ type: ON_RULE_EDIT });

export const onEditSuccess = rules => ({ type: ON_RULE_EDIT_SUCCESS, rules });

export const onCreate = () => ({ type: ON_RULE_CREATE });

export const onDuplicateSuccess = rule => ({ type: ON_RULE_DUPLICATE_SUCCESS, rule });

export const onCreateSuccess = rule => ({ type: ON_RULE_CREATE_SUCCESS, rule });

export const onDelete = () => ({ type: ON_RULE_DELETE });

export const onDeleteConfirm = data => ({ type: ON_RULE_DELETE_CONFIRM, data });

export const onResetFlags = () => ({ type: ON_RULE_FLAGS_RESET });

export const onItemSelect = payload => ({ type: ON_RULE_SELECT, payload });

export const onUpload = () => ({ type: ON_RULE_UPLOAD });

export const onUploadRuleSuccess = (rules) => ({ type: ON_RULE_UPLOAD_SUCCESS, rules });

export const onUpdateSelectedFilter = (selectedFilter) => ({ type: ON_RULE_UPDATE_SELECTED_FILTER, selectedFilter });

export const downloadRules = (mediaType, rulesIds) => {
  return (dispatch) => {
    dispatch(addLoader(RULES_LIST_LOADER));
    return rulesService.downloadRules(mediaType, rulesIds)
      .then((data) => {
        download(data, dispatch);
      })
      .catch((errorData) => {

        const { error: { response: { data } = {} } } = errorData;

        batch(() => {
          dispatch(removeLoader(RULES_LIST_LOADER));
          dispatch(showMessage(data, NOTIFICATION_TYPE_ERROR));
        });
      });
  };
};

const download = (data, dispatch) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = downloadUrl;
  link.setAttribute('download', 'delivery-window-calculation-rules.xlsx');
  document.body.appendChild(link);
  link.click();
  link.remove();
  dispatch(removeLoader(RULES_LIST_LOADER));
};

export const deleteRules = (rulesIds) => {
  return (dispatch) => {
    rulesService.deleteRules(rulesIds)
      .then((data) => {
        const success = data.filter(elem => elem.status !== 'error');
        const errors = data.filter(elem => elem.status === 'error');
        dispatch(onDeleteConfirm(success));
        if (success.length > 0) {
          dispatch(showMessage(SUCCESS_MESSAGES.rulesDeleted));
        }
        if (errors.length > 0) {
          dispatch(showMessage(errors, NOTIFICATION_TYPE_ERROR));
        }
        dispatch(getRulesList({ filter: 'getAll(true)', mediaType: 'JSON' }));
        dispatch(enableButton(DELETE));
      });
  };
};

export const updateRules = (updatedRules) => {
  return (dispatch) => {
    return rulesService.updateRules(updatedRules)
      .then((data) => {
        const success = data.filter(elem => elem.status !== 'error');
        const errors = data.filter(elem => elem.status === 'error');
        dispatch(onEditSuccess(success));
        if (success.length > 0) {
          dispatch(showMessage(SUCCESS_MESSAGES.rulesUpdated));
        }
        if (errors.length > 0) {
          dispatch(showMessage(errors, NOTIFICATION_TYPE_ERROR));
        }
        dispatch(getRulesList({ filter: 'getAll(true)', mediaType: 'JSON' }));
        dispatch(enableButton(UPDATE));
      });
  };
};

export const createNewRule = (rule) => {

  return (dispatch) => {
    return rulesService.createRule(rule)
      .then((data) => {
        batch(() => {
          dispatch(onCreateSuccess(data));
          dispatch(showMessage(SUCCESS_MESSAGES.ruleCreated));
        });
      })
      .catch((errorData) => {
        const { error: { response: { data } = {} } } = errorData;
        dispatch(showMessage(data, NOTIFICATION_TYPE_ERROR));
      });
  };
};

export const duplicateRule = (rule) => {
  return (dispatch) => {
    dispatch(onDuplicateSuccess(rule));
    dispatch(showMessage({
      'errors': [DUPLICATE_MESSAGE],
      'message': DUPLICATE_MESSAGE.title,
    }, NOTIFICATION_TYPE_ERROR));
  };
};

export const uploadRules = (file) => {
  return (dispatch) => {
    dispatch(disableButton(UPLOAD));
    return rulesService.uploadRules(file, response => {
      const message = response.headers.status === NOTIFICATION_TYPE_WARNING ? WARNING_MESSAGES.rulesUploaded : SUCCESS_MESSAGES.rulesUploaded;
      batch(() => {
        download(response.data, dispatch);
        dispatch(showMessage(message, response.headers.status));
        dispatch(getRulesList({ filter: 'getAll(true)', mediaType: 'JSON' }));
        dispatch(enableButton(UPLOAD));
      });
    },
    errorData => {
      const { error: { response: { data } = {} } } = errorData;
      batch(() => {
        dispatch(removeLoader(RULES_LIST_LOADER));
        dispatch(showMessage(data, NOTIFICATION_TYPE_ERROR));
        dispatch(enableButton(UPLOAD));
      });
    });
  };
};
