import React, { memo } from 'react';
import Zoom from '@material-ui/core/Zoom';
import { PLUS_END_DATES, PLUS_START_DAYS, tableTitles } from '../../constants/rules';

import './ConfirmationToast.scss';

const transitionDuration = 200;

export const CN = 'fo-confirmation-toast';

export const ConfirmationToast = memo(({ counter, onCancel, onConfirm }) => (
  <Zoom in timeout={transitionDuration}>
    <div className={CN}>
      <div
        className={`${CN}__message`}
      >
        Do you really want to apply new <span className={`${CN}__bold`}>{tableTitles[PLUS_START_DAYS]}</span> and <span className={`${CN}__bold`}>{tableTitles[PLUS_END_DATES]}</span> to <span className={`${CN}__bold`}>{counter}</span> {counter === 1 ? 'rule' : 'rules'}?
      </div>
      <div className={`${CN}-actions`}>
        <div onClick={onConfirm} className={`${CN}__action`}>Yes</div>
        <div className={`${CN}__divider`} />
        <div onClick={onCancel} className={`${CN}__action`}>No</div>
      </div>
    </div>
  </Zoom>
));
