/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';
import { matchPath, Route, Switch, Redirect } from 'react-router';
import { SecureRoute, LoginCallback, withOktaAuth } from '@okta/okta-react';
import services from '../services';
import { routePath } from '../utils/url';
import NotFoundPage from '../containers/not-found-page/NotFoundPage';
import { DeliveryWindowPage } from '../containers/delivery-window';
import { PageLayout } from './page-layout';
import { DELIVERY_WINDOW_WRITE_AUTH } from '../constants/rules';

import { destructUrlPattern } from '../constants/urls';


// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const CN = 'delivery-window-ui';

const loggedInUserInfo = (oktaAuth) => {
  const { authStateManager } = oktaAuth;
  if (oktaAuth && authStateManager && authStateManager._authState && authStateManager._authState.idToken && authStateManager._authState.idToken.claims) {
    const { name, groups } = authStateManager._authState.idToken.claims;
    const writePerm = DELIVERY_WINDOW_WRITE_AUTH.filter(auth => groups.includes(auth)).length > 0;
    return {
      name,
      writePerm,
    };
  }
  return null;
};

class AppInternal extends Component {

  render() {
    const {
      config: {
        pageLayoutConfig,
        client: {
          routing: {
            rules,
          },
        },
      },
    } = services;
    const {
      location,
      authState,
      oktaAuth,
    } = this.props;


    const { isDarkOn } = this.context;
    const {
      params: {
        path,
      } = {},
    } = matchPath(location.pathname, { path: destructUrlPattern }) || {};

    const userInfo = loggedInUserInfo(oktaAuth);

    services.apiAdapter.setAuthState(authState);


    return (
      <PageLayout
        isDarkOn={isDarkOn}
        path={path}
        {...pageLayoutConfig[path]}
        className={`${CN}__${path}`}
        isUnauthorized
        oktaAuth={oktaAuth}
      >

        <Switch>
          <Route path="/login/callback" component={LoginCallback} />

          <SecureRoute
            exact
            path={routePath(rules.path)}
            component={() => <DeliveryWindowPage userInfo={userInfo} />}
          />

          <Redirect
            exact
            from={routePath('/')}
            to={rules.path}
          />

          <Route component={NotFoundPage} />
        </Switch>
      </PageLayout>
    );
  }
}

export default withOktaAuth(AppInternal);
