import { connect } from 'react-redux';

import services from '../../services';

import { Nav as NavComponent } from './Nav';

const {
  config: {
    client: {
      routing: {
        rules: {
          path: rulesPath,
        },
      },
    },
  },
} = services;

export const mapStateToProps = ({ router: { location } }) => ({
  rulesPath,
  location,
});

export const Nav = connect(mapStateToProps)(NavComponent);
