/* eslint-disable */
import React from 'react';

import './Wave.scss';

const CN = 'fo-waves';

export const Wave = () => (
  <svg className={CN} viewBox="0, 0, 1440, 98" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"
       width="100%"
       height="100%"
  >
    <defs>
      <linearGradient x1="2.90146315%" y1="49.7818604%" x2="100%" y2="50.2315779%" id="linearGradient-1">
        <stop stopColor="#7D9FFF" offset="0%"></stop>
        <stop stopColor="#3EE5A1" offset="100%"></stop>
      </linearGradient>
      <rect id="path-2" x="0" y="0" width="1440" height="98"></rect>
      <linearGradient x1="2.90146315%" y1="49.7818604%" x2="100%" y2="50.2315779%" id="linearGradient-4">
        <stop stopColor="#A80C8F" offset="0%"></stop>
        <stop stopColor="#B5990F" offset="100%"></stop>
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-5">
        <stop stopColor="#900000" offset="0%"></stop>
        <stop stopColor="#FF0B0B" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g id="Delivery-Window-Tool" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Delivery-Window-Main" transform="translate(0.000000, -60.000000)">
        <g id="Header">
          <g id="Tools-Panel" transform="translate(0.000000, 60.000000)">
            <g id="GFX/Header-Delivery-Window">
              <rect id="Rectangle" fill="url(#linearGradient-4)" fillRule="evenodd" mask="url(#mask-3)" x="0" y="0" width="1440" height="98"></rect>
              <g id="Group" strokeWidth="1" fillRule="evenodd" mask="url(#mask-3)" fill="url(#linearGradient-5)">
                <g transform="translate(-24.000000, 3.000000)">
                  <polygon id="Polygon" points="49 18 91.4352448 42.5 91.4352448 91.5 49 116 6.56475521 91.5 6.56475521 42.5"></polygon>
                  <polygon id="Polygon-Copy" points="160 27 183.382686 40.5 183.382686 67.5 160 81 136.617314 67.5 136.617314 40.5"></polygon>
                  <polygon id="Polygon-Copy-2" points="396 0 419.382686 13.5 419.382686 40.5 396 54 372.617314 40.5 372.617314 13.5"></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
