import React from 'react';
import cx from 'classnames';

import { Wave } from '../svgComponents/Wave';

import './NavigationBanner.scss';

const CN = 'navigation-banner';

export const NavigationBanner = ({ navIsCollapsed }) => (
  <div className={cx(CN, { [`${CN}--collapsed`]: navIsCollapsed })}>
    <Wave className={CN} />
  </div>
);
