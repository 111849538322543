import React, { Component } from 'react';
import { array, string, bool, object } from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

import { generatePathWithParamsAndSearch } from '../../utils/url';
import { NavigationBanner } from './navigation-banner';
import { themeContext } from '../../context/ThemeContext';
import { ErrorBoundary } from '../error-boundary';
import './Nav.scss';

const CN = 'navigation';

export class Nav extends Component {
  static contextType = themeContext;

  generateRouteLink = (link) => {
    // eslint-disable-next-line
    const { isDarkOn } = this.context;

    return generatePathWithParamsAndSearch(`${link}`);
  };

  renderNavLinks = (links) => {
    const { isDarkOn } = this.context;

    return links.map(({ path, label }) => (
      <ErrorBoundary key={label}>
        <NavLink
          exact
          className={cx([`${CN}__link`], { [`${CN}__link--dark-theme`]: isDarkOn })}
          activeClassName={`${CN}__link--active`}
          to={this.generateRouteLink(path)}
        >
          {label}
        </NavLink>
      </ErrorBoundary>
    ));
  };

  render() {
    const { links, navIsCollapsed } = this.props;

    return (
      <nav
        className={cx(CN, {
          [`${CN}__collapsed`]: navIsCollapsed,
        })}
      >
        <div
          className={`${CN}__content-holder`}
        >
          <NavigationBanner navIsCollapsed={navIsCollapsed} />

          {!!links.length && (
            <div className="container">{this.renderNavLinks(links)}</div>
          )}
        </div>
      </nav>
    );
  }
}

Nav.propTypes = {
  links: array,
  navIsCollapsed: bool,
  selectedFilters: object, // eslint-disable-line
  rulesPath: string, // eslint-disable-line
};

Nav.defaultProps = {
  links: [],
};
