import withStyles from '@material-ui/core/styles/withStyles';
import { TooltipWrapper as Tooltip } from './TooltipWrapper';

export const styles = () => ({
  tooltipPlacementTop: {
    margin: '-8px 0 0',
  },
});

export const TooltipWrapper = withStyles(styles)(Tooltip);
