import React, { memo } from 'react';
import { string } from 'prop-types';
import cx from 'classnames';
import { Multiselect } from 'multiselect-react-dropdown';

import { TooltipWrapper } from '../../TooltipWrapper';

import './SortField.scss';


export const CN = 'fo-sort-field';


export const nameIdConverter = (filters) => {
  return filters.map(filter => ({ 'name': filter, 'id': filter }));
};

export const nameIdToIdConverter = (selected) => {
  return selected.map(select => select.id);
};

export const SortField = memo((props) => {
  const { label, withBackground, filterValues, selectedFilter, showFilter } = props;
  let noOfSelection = selectedFilter.length;
  const filterSelect = (selected) => {
    const { onFilterSelect } = props;
    noOfSelection = selected.length;
    const array = nameIdToIdConverter(selected);
    onFilterSelect(array);
  };

  const filterStyles = {
    multiselectContainer: {
      width: '60%',
      float: 'right',
    },
    optionContainer: {
      border: '1px solid',
    },
    inputField: {
      margin: '2px',
    },
    chips: {
      display: 'none',
    },
  };

  return (
    <div
      className={cx(CN, {
        [`${CN}--with-background`]: withBackground,
      })}
    >
      <TooltipWrapper val={label}>
        <span
          className={cx(`${CN}__label`, {
            [`${CN}__label--with-background`]: withBackground,
          })}
        >
          {label}
        </span>
      </TooltipWrapper>
      <span className={cx(`${CN}__labelFilter`, `${CN}__${label.replace(/ /g, '')}`)}>
        {showFilter && filterValues && (
          <Multiselect
            id={`${label}__select`}
            onSelect={filterSelect}
            onRemove={filterSelect}
            options={nameIdConverter(filterValues)}
            selectedValues={nameIdConverter(selectedFilter)}
            displayValue="name"
            showCheckbox
            hidePlaceholder
            showArrow
            style={filterStyles}
            avoidHighlightFirstOption
          />
        )}
        {showFilter && filterValues && noOfSelection != 0 && (
          <span className="selectionMarker">
            {noOfSelection}
          </span>
        )}
      </span>
    </div>
  );
});


SortField.propTypes = {
  label: string,
};

SortField.defaultProps = {};
