export const GET_RULES = 'GET_RULES';
export const GET_RULES_SUCCESS = 'GET_RULES_SUCCESS';

export const ON_RULE_SELECT = 'ON_RULE_SELECT';
export const ON_RULE_TOGGLE = 'ON_RULE_TOGGLE';

export const ON_RULE_EDIT = 'ON_RULE_EDIT';
export const ON_RULE_EDIT_SUCCESS = 'ON_RULE_EDIT_SUCCESS';

export const ON_RULE_CREATE = 'ON_RULE_CREATE';
export const ON_RULE_CREATE_SUCCESS = 'ON_RULE_CREATE_SUCCESS';

export const ON_RULE_DUPLICATE_SUCCESS = 'ON_RULE_DUPLICATE_SUCCESS';

export const ON_RULE_DELETE = 'ON_RULE_DELETE';
export const ON_RULE_DELETE_CONFIRM = 'ON_RULE_DELETE_CONFIRM';

export const ON_RULE_FLAGS_RESET = 'ON_RULE_FLAGS_RESET';
export const ON_RULE_ALL_TOGGLE = 'ON_RULE_ALL_TOGGLE';

export const ON_RULE_UPLOAD = 'ON_RULE_UPLOAD';
export const ON_RULE_UPLOAD_SUCCESS = 'ON_RULE_UPLOAD_SUCCESS';

export const ON_RULE_UPDATE_SELECTED_FILTER = 'ON_RULE_UPDATE_SELECTED_FILTER';


export const ON_BUTTON_DISABLE = 'ON_BUTTON_DISABLE';
export const ON_BUTTON_ENABLE = 'ON_BUTTON_ENABLE';
