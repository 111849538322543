import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

import { Notification as Tooltip } from './Notification';
import * as uiActions from '../../actions/ui.action';

export CN from './Notification';

export const mapStateToProps = (state) => {
  const {
    notifications: { message, title, type },
  } = state;
  return {
    message,
    title,
    type,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...uiActions }, dispatch),
  };
};

export const Notification = connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Tooltip));
