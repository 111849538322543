import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router';

import * as uiActions from '../../actions/ui.action';
import HeaderComponent from './Header';

export const mapDispatchToProps = dispatch => ({ actions: bindActionCreators({ ...uiActions }, dispatch) });

export const Header = compose(
  withRouter,
  connect(null, mapDispatchToProps),
)(HeaderComponent);
