import { withRouter } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { AddEditModal } from './AddEditModal';
import { onResetFlags, createNewRule, updateRules, duplicateRule, disableButton } from '../../actions/rules.action';

export const mapStateToProps = (state) => {
  const {
    ui: { loadersList },
    router: { location: { search } },
    rules: {
      rulesList,
      data,
      sortOrder,
      sortOption,
      selectedIds,
      isSelectedAll,
      selectedRow,
      filtersData,
      selectedFilters,
      originalData,
      showEdit,
      showDelete,
    },
  } = state;
  return {
    loadersList,
    search,
    rulesList,
    data,
    sortOrder,
    sortOption,
    selectedIds,
    isSelectedAll,
    selectedRow,
    filtersData,
    selectedFilters,
    originalData,
    showEdit,
    showDelete,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({
      onResetFlags,
      updateRules,
      createNewRule,
      duplicateRule,
      disableButton,
    }, dispatch),
  };
};

export const AddEditModalContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddEditModal);
