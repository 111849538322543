import React, { Component } from 'react';
import Input from 'react-toolbox/lib/input';
import cx from 'classnames';
import { bool, func, string, number } from 'prop-types';
import './Textarea.scss';

export const CN = 'fo-textarea';

export class TextArea extends Component {

  onChange = (value) => {
    const { onChange } = this.props;

    onChange && onChange(value);
  };

  render() {
    const {
      placeholder,
      disabled,
      validator,
      classModifier,
      className,
      rows,
      resizable,
      value,
      maxLength,
    } = this.props;

    return (
      <Input
        multiline
        rows={rows}
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={this.onChange}
        onBlur={validator}
        className={cx({ className: !!className })}
        theme={{
          input: cx(
            CN,
            {
              [`${CN}--${classModifier}`]: !!classModifier,
              [`${CN}--resizable`]: !!resizable,
            },
          ),
        }}
        maxLength={maxLength}
      />
    );
  }
}

TextArea.propTypes = {
  classModifier: string,
  className: string,
  value: string,
  disabled: bool,
  placeholder: string,
  onChange: func,
  validator: func,
  rows: number,
  resizable: bool,
  maxLength: number,
};

TextArea.defaultProps = {
  className: '',
  classModifier: '',
  disabled: false,
  placeholder: '',
  validator: null,
  rows: 5,
  resizable: false,
  maxLength: undefined,
};
