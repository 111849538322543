import React, { memo } from 'react';
import { func, bool } from 'prop-types';
import get from 'lodash/get';

import { FileUploader } from '../file-uploader/FileUploader';
import { Popup } from '../popup';

import './UploadModal.scss';

export const CN = 'fo-upload-modal';

const LABEL = 'label';

const content = (item) => {
  const messages = {
    [LABEL]: 'Upload rules',
  };

  return get(messages, item, '');
};

export const UploadModal = memo(({
  onApply,
  isOpen,
  onClose,
}) => {
  const renderHeading = () => <div>{content(LABEL)}</div>;

  return (
    <Popup
      heading={renderHeading()}
      isOpen={isOpen}
      onClose={onClose}
      className={CN}
    >
      <FileUploader
        onRuleUploadConfirm={onApply}
        onClose={onClose}
      />
    </Popup>
  );
});

UploadModal.propTypes = {
  onClose: func.isRequired,
  onApply: func.isRequired,
  isOpen: bool,
};
