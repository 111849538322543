import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import { routePath } from '../../utils/url';
import { SvgIcon } from '../../components/svg-icon/SvgIcon';
import { Button } from '../../components/button';
import { ThemeConsumer } from '../../context/ThemeContext';

import '../../assets/icons/nike-logo.svg';
import './NotFoundPage.scss';

export const CN = 'not-found';

export const NotFoundPage = ({ history: { push } }) => {
  const goHome = () => push(routePath('rules'));

  return (
    <ThemeConsumer>
      {
        ({ isDarkOn }) => (
          <div className={cx(CN, { [`${CN}--dark-theme`]: isDarkOn })}>
            <SvgIcon
              name="nike-logo"
              className={cx([`${CN}__nike-logo`], { [`${CN}__nike-logo--dark-theme`]: isDarkOn })}
            />
            <div className={`${CN}__info`}>
              <div className={`${CN}__info__heading`}>Error 404</div>
              <div className={`${CN}__info__subheading`}>
                We can&apos;t find page you are looking for
              </div>
            </div>
            <Button
              className={cx([`${CN}__go-back`], { [`${CN}__go-back--dark-theme`]: isDarkOn })}
              style="primary"
              onClickHandler={goHome}
              label="Back to main page"
            />
          </div>
        )
      }
    </ThemeConsumer>
  );
};

export default withRouter(NotFoundPage);
