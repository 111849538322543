import React, { Component } from 'react';
import { bool, func, string, oneOfType, number } from 'prop-types';
import Input from 'react-toolbox/lib/input';
import cx from 'classnames';

import { SvgIcon } from '../svg-icon/SvgIcon';

import '../../assets/icons/loupe.svg';
import './TextField.scss';

const CN = 'fo-text-field';

export class TextField extends Component {

  onChange = (value) => {
    const { onChange, type } = this.props;
    let val = value;

    if (type === 'number') {
      if (val == Math.floor(val)) {
        val = Number(val);
      } else {
        val = 0;
      }
    }
    onChange && onChange(val);
  };

  render() {
    const { value, placeholder, disabled, className, name, validation, isSearch, autoComplete, type, errorMessage } = this.props;
    return (
      <Input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        name={name}
        value={value}
        icon={isSearch ? <SvgIcon className={`${CN}__loupe`} name="loupe" /> : null}
        onChange={this.onChange}
        onBlur={validation}
        theme={{
          input: cx(CN, className, { [`${CN}__search`]: isSearch }),
        }}
        autoComplete={autoComplete ? 'on' : 'off'}
        error={errorMessage}
        className={errorMessage ? 'hasError' : ''}
      />
    );
  }
}

TextField.propTypes = {
  className: string,
  value: oneOfType([string, number]),
  disabled: bool,
  placeholder: string,
  name: string.isRequired,
  onChange: func.isRequired,
  validation: func,
  isSearch: bool,
  autoComplete: bool,
  type: string,
};

TextField.defaultProps = {
  className: '',
  disabled: false,
  placeholder: '',
  validation: null,
  isSearch: false,
  autoComplete: false,
  type: 'text',
};
