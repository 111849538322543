import React, { memo } from 'react';
import cx from 'classnames';
import { ErrorBoundary } from '../error-boundary';
import { Modal } from '../modal';

import './Popup.scss';

const CN = 'fo-popup';

export const Popup = memo(({
  children,
  isOpen,
  onClose,
  heading,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      handleModalToggle={onClose}
    >
      <div className={cx(CN, className)}>
        <div className={`${CN}__label`}>{heading}</div>

        <ErrorBoundary>
          {children}
        </ErrorBoundary>

      </div>
    </Modal>
  );
});
