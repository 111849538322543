import React, { Component } from 'react';
import { bool, array, func, string, object, shape, instanceOf, arrayOf } from 'prop-types';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import cx from 'classnames';
import xor from 'lodash/xor';

import { Checkbox } from '../checkbox/Checkbox';
import { TextField } from '../text-field';
import { SvgIcon } from '../svg-icon/SvgIcon';
import { ErrorBoundary } from '../error-boundary';

import './Dropdown.scss';

import '../../assets/icons/chevron-up.svg';
import '../../assets/icons/chevron-down.svg';

export const CN = 'fo-dropdown';

export class Dropdown extends Component {

  constructor(props) {
    super(props);

    const { options, multiple, selected } = props;

    this.state = {
      dropdownOpen: false,
      options: [...options],
      optionsToDisplay: [...options],
      selectedOptions: selected,
      searchValue: '',
    };

    this.renderOption = multiple ? this.renderCheckboxOption : this.renderSimpleOption;
    this.wrapperRef = React.createRef();
    this.dropdownCloser = this.dropdownCloser.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onSearchHandler = this.onSearchHandler.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const nextSelected = props.selected;
    const prevSelected = state.selectedOptions;

    const optionsProps = props.options;
    const optionsState = state.options;

    if (!isEqual(optionsProps, optionsState) || !isEqual(nextSelected, prevSelected)) {
      return {
        options: [...optionsProps],
        optionsToDisplay: [...optionsProps],
        searchValue: '',
        selectedOptions: [...nextSelected],
      };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('click', this.dropdownCloser, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.dropdownCloser, true);
  }

  onOptionSelect = (e) => {
    const { currentTarget: { dataset: { option = null } } } = e;

    const { onSelect, multiple, onClose } = this.props;
    const { selectedOptions, dropdownOpen } = this.state;
    const selected = multiple ? xor(selectedOptions, [option]) : [option];

    this.setState({
      selectedOptions: selected,
      dropdownOpen: multiple ? dropdownOpen : false,
    });

    onSelect && onSelect(selected);
    !multiple && onClose && onClose();
  };

  onSelectAll = () => {
    const { options, onSelect } = this.props;

    onSelect && onSelect([...options]);
  };

  onResetAll = () => {
    const { onSelect } = this.props;

    onSelect && onSelect([]);
  };

  onSearchHandler(searchValue) {
    const { options } = this.state;
    const { labels } = this.props;

    const optionsToDisplay = !searchValue
      ? [...options]
      : options.filter(item => {
        if (item) {
          const { [item]: value = item } = labels;
          return value.toLowerCase().includes(searchValue.toLowerCase());
        }
      });

    this.setState({
      optionsToDisplay,
      searchValue,
    });
  }

  dropdownCloser({ target }) {
    const { blurExceptionNodes = [], onClose } = this.props;
    const { dropdownOpen } = this.state;
    const preventClosing = blurExceptionNodes.length && blurExceptionNodes.some(({ current }) => current.contains(target));

    if (dropdownOpen && !this.wrapperRef.current.contains(target) && !preventClosing) {
      dropdownOpen && this.setState({ dropdownOpen: false });
      onClose && onClose();
    }
  }

  dropdownToggle() {
    this.setState((state) => {
      const { dropdownOpen } = state;
      const { onClose } = this.props;

      dropdownOpen && onClose && onClose();

      return { dropdownOpen: !dropdownOpen };
    });
  }

  renderSimpleOption = (option) => {
    const { labels } = this.props;
    const { selectedOptions: [selectedOption] } = this.state;
    const label = labels[option] || option || 'N/A';

    return (
      <div
        key={option}
        data-option={option}
        onClick={this.onOptionSelect}
        className={cx(`${CN}__option`, { [`${CN}__selected-option`]: selectedOption === option })}
      >
        {label}
      </div>
    );
  };

  renderCheckboxOption = (option) => {
    const { labels } = this.props;
    const { selectedOptions } = this.state;
    const label = labels[option] || option || 'N/A';

    return (
      <div
        onClick={this.onOptionSelect}
        key={option}
        data-option={option}
        className={`${CN}__option`}
      >
        <ErrorBoundary>
          <Checkbox
            name={option}
            checked={selectedOptions.includes(option)}
            label={label}
          />
        </ErrorBoundary>
      </div>
    );
  };

  renderOptions = () => {
    const { optionsToDisplay = [] } = this.state;
    if (!optionsToDisplay.length) {
      return (
        <div className={`${CN}__label`}>No options found...</div>
      );
    }
    return optionsToDisplay.map(this.renderOption);
  };

  renderSearch = () => {
    const { withSearch } = this.props;
    const { searchValue } = this.state;

    if (!withSearch) return null;

    return (
      <div className={`${CN}__search`}>
        <TextField
          name={`${CN}__search-name`}
          placeholder="Search"
          isSearch
          value={searchValue}
          onChange={this.onSearchHandler}
          autoComplete={false}
        />
      </div>
    );
  };

  renderBulkActions = () => {
    const { withBulkActions, label } = this.props;

    if (!withBulkActions) return null;

    return (
      <div className={`${CN}__bulk-actions`}>
        <div className={`${CN}__bulk-actions-label`}>{label}</div>
        <div className={`${CN}__bulk-actions-row`}>
          <div
            className={`${CN}__bulk-actions-item ${CN}__select-all`}
            onClick={this.onSelectAll}
          >
            Select all
          </div>
          <div
            className={`${CN}__bulk-actions-item ${CN}__reset-all`}
            onClick={this.onResetAll}
          >
            Clear all
          </div>
        </div>
      </div>
    );
  };

  renderDropdownArrow = () => {
    const { dropdownOpen } = this.state;
    const arrowSvg = `chevron-${dropdownOpen ? 'up' : 'down'}`;

    return (
      <SvgIcon name={arrowSvg} className={`${CN}__arrow`} />
    );
  };

  renderSelectField = () => {
    const { multiple, defaultOption, labels } = this.props;
    const { selectedOptions, selectedOptions: [firstSelected, secondSelected], options } = this.state;

    let selected = labels[firstSelected] || options.find(el => selectedOptions.includes(el));
    let displayedValue = labels[selected || defaultOption] || selected || defaultOption;

    if (isNull(selected)) {
      selected = 'N/A';
      displayedValue = 'N/A';
    }

    const selectedView = secondSelected
      ? `${selected} (+${selectedOptions.length - 1} more)`
      : displayedValue;

    return (
      <div
        onClick={this.dropdownToggle}
        className={`${CN}__selected-field`}
      >
        {multiple && selectedView}
        {!multiple && displayedValue}
        {this.renderDropdownArrow()}
      </div>
    );
  };

  render() {
    const { withSearch, className, fluid } = this.props;
    const { dropdownOpen, options } = this.state;

    return (
      <div
        tabIndex={0}
        ref={this.wrapperRef}
        className={cx(CN, {
          [`${CN}--searchable`]: withSearch,
          [`${CN}--disabled`]: !options.length,
          [`${CN}--fluid`]: fluid,
        }, className)}
      >

        {this.renderSelectField()}

        {
          dropdownOpen && (
            <div className={`${CN}__wrapper`}>
              {this.renderSearch()}
              {this.renderBulkActions()}
              <div className={`${CN}__options`}>
                {this.renderOptions()}
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: array.isRequired,
  onSelect: func.isRequired,
  multiple: bool,
  withSearch: bool,
  withBulkActions: bool,
  defaultOption: string,
  className: string,
  selected: array,
  labels: object,
  label: string,
  fluid: bool,
  blurExceptionNodes: arrayOf(shape({ current: instanceOf(Element) })),
  onClose: func,
};

Dropdown.defaultProps = {
  multiple: false,
  withSearch: false,
  withBulkActions: false,
  defaultOption: 'Default Option',
  className: '',
  selected: [],
  labels: {},
  fluid: false,
};
