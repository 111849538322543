import React, { Component } from 'react';
import { string, node, arrayOf, oneOfType, bool, array } from 'prop-types';
import cx from 'classnames';
import throttle from 'lodash/throttle';

import { Nav } from '../navigation-bar';
import { Header } from '../header';
import { ErrorBoundary } from '../error-boundary';
import { Notification } from '../notification';

import './PageLayout.scss';

const CN = 'page-layout';

export class PageLayout extends Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();
    this.state = {
      currentScrollPos: 0,
      navIsCollapsed: false,
    };

    this.onScrollHandler = throttle(this.onScrollHandler, 200);
  }

  componentDidMount() {
    this.rootRef.current.addEventListener('scroll', this.onScrollHandler);
  }

  componentDidUpdate() {
    const { navIsCollapsed } = this.state;
    const { trackScrollPos } = this.props;

    if (navIsCollapsed && !trackScrollPos) {
      // eslint-disable-next-line
      this.setState({ navIsCollapsed: false, currentScrollPos: 0 });
    }
  }

  componentWillUnmount() {
    this.rootRef.current.removeEventListener('scroll', this.onScrollHandler);
  }

  renderNavbar = () => {
    const {
      withNavbar,
      links,
      path,
    } = this.props;
    const { navIsCollapsed } = this.state;

    if (!withNavbar) return null;

    return (
      <ErrorBoundary>
        <Nav
          path={path}
          links={links}
          navIsCollapsed={navIsCollapsed}
        />
      </ErrorBoundary>
    );
  };

  onScrollHandler = () => {
    const { trackScrollPos } = this.props;

    if (!trackScrollPos) return;

    const { currentScrollPos, navIsCollapsed } = this.state;
    const { current: { scrollTop } } = this.rootRef;

    if (!currentScrollPos && scrollTop > currentScrollPos) {
      this.setState({
        currentScrollPos: scrollTop,
        navIsCollapsed: !navIsCollapsed,
      });
    } else if (currentScrollPos && !scrollTop) {
      this.setState({
        currentScrollPos: 0,
        navIsCollapsed: !navIsCollapsed,
      });
    }
  };

  render() {
    const {
      className,
      isDarkOn,
      children,
      oktaAuth,
    } = this.props;

    return (
      <div className={cx(CN, `${className}__page-layout`)}>

        <ErrorBoundary>
          <Header oktaAuth={oktaAuth} />
        </ErrorBoundary>

        {this.renderNavbar()}

        <div
          ref={this.rootRef}
          className={cx(
            `${CN}__container`,
            `${className}__container`,
            {
              [`${CN}__container--dark-theme`]: isDarkOn,
            },
          )}
        >
          {children}
        </div>
        <Notification />
        <footer className="footer">
          <span className="footer-text">© 2021 Nike, Inc. All Rights Reserved</span>
        </footer>
      </div>
    );
  }
}

PageLayout.propTypes = {
  className: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  links: array,
  isDarkOn: bool,
  withNavbar: bool,
  trackScrollPos: bool,
};

PageLayout.defaultProps = {
  className: '',
  children: null,
  links: [],
  isDarkOn: false,
  withNavbar: false,
};
