import React, { Component } from 'react';
import { string, func, object, oneOf, bool } from 'prop-types';
import cx from 'classnames';

import { AUTO_HIDE_DELAYS, NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_WARNING } from '../../constants/notification';
import { SvgIcon } from '../svg-icon/SvgIcon';
import '../../assets/icons/close.svg';
import '../../assets/icons/checked.svg';
import './Notification.scss';

export const CN = 'fo-notification';
const SUCCESS = `${CN}--success`;
const ERROR = `${CN}--error`;
const WARN = `${CN}--warn`;

export class Notification extends Component {
  componentDidUpdate() {
    const {
      message,
      title,
      type,
      enqueueSnackbar,
      actions: { resetNotificationData },
      turnOff,
    } = this.props;

    if (!message) return;

    const notificationInfo = { message, title, type };

    !turnOff && enqueueSnackbar(notificationInfo.title, {
      autoHideDuration: AUTO_HIDE_DELAYS[type],
      children: key => (
        <div>{this.renderTooltipContent(notificationInfo, key)}</div>
      ),
      onExiting: this.fireActionsOnExit,
      onClose: this.fireActionsOnExit,
    });

    resetNotificationData();
  }

  fireActionsOnExit = () => {
  };

  closeHandler = (key) => {
    return () => {
      const { closeSnackbar } = this.props;
      closeSnackbar && closeSnackbar(key);
    };
  };

  renderMessageIcon = (type) => {
    const name = type === NOTIFICATION_TYPE_ERROR ? 'close' : 'checked';
    const baseClass = `${type === NOTIFICATION_TYPE_ERROR ? ERROR : type === NOTIFICATION_TYPE_WARNING ? WARN : SUCCESS}-icon`;

    return (
      <SvgIcon
        name={name}
        className={cx(baseClass, `${CN}__icon`)}
      />
    );
  };

  renderTooltipContent = ({ type, title, message }, key) => {
    const baseClass = type === NOTIFICATION_TYPE_ERROR ? ERROR : (type === NOTIFICATION_TYPE_WARNING ? WARN : SUCCESS);

    return (
      <div className={cx(CN, baseClass)}>
        <div className={`${CN}__head`}>
          <div className={cx(`${baseClass}-general-info`, `${CN}__general-info`)}>
            {this.renderMessageIcon(type)}
            <div className={`${CN}__heading`}>{title}</div>
          </div>
          <SvgIcon
            onClick={this.closeHandler(key)}
            name="close"
            className={`${CN}__closer`}
          />
        </div>
        <div className={`${CN}__message`}>
          {message}
        </div>
      </div>
    );
  };

  render() {
    return null;
  }
}

Notification.propTypes = {
  message: string.isRequired,
  title: string.isRequired,
  enqueueSnackbar: func.isRequired,
  closeSnackbar: func.isRequired,
  type: oneOf([...Object.keys(AUTO_HIDE_DELAYS), '']).isRequired,
  actions: object.isRequired,
  turnOff: bool,
};
