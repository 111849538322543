import * as React from 'react';
import cx from 'classnames';
import { func } from 'prop-types';

import { FileUploadConfirmationToast } from '../confirmation-toast';
import { SvgIcon } from '../svg-icon/SvgIcon';

import '../../assets/icons/upload.svg';

import './FileUploader.scss';
import { Button, BUTTON_STYLES } from '../button';

const FILE_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const CANCEL_LABEL = 'Cancel upload';
export const CN = 'file-uploader-wrapper';

export class FileUploader extends React.Component {
  state = {
    file: null,
    dragOver: false,
    errorNotification: null,
  };

  fileInputRef = React.createRef();

  onRuleUploadConfirm = (file) => {
    const { onRuleUploadConfirm } = this.props;

    onRuleUploadConfirm && onRuleUploadConfirm(file);
  };

  /*
   Drag and Drop Event Handlers
   */
  handleDragEnter = (e) => {
    e.preventDefault();
  };

  handleDragOver = (e) => {
    const { dragOver } = this.state;

    e.preventDefault();
    if (!dragOver) {
      this.setState({
        dragOver: true,
      });
    }
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState({
      dragOver: false,
    });
  };

  handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    // Validate file is of type Image
    const fileType = file.type.split()[0];

    if (fileType !== FILE_TYPE_XLSX) {
      this.setState({
        file: null,
        errorNotification: 'Not an .xlsx File',
        dragOver: false,
      });
      return setTimeout(() => {
        this.setState({
          errorNotification: null,
        });
      }, 3000);
    }

    this.fileInputRef.current.files = e.dataTransfer.files;
    this.setState({
      file,
      dragOver: false,
    });
  };

  /*
   Handle Manually (File Input) Added Files
   */
  handleAddFile = (e) => {
    e.preventDefault();
    const file = this.fileInputRef.current.files[0];

    // Validate file is of type Image
    const fileType = this.fileInputRef.current.files[0].type.split()[0];

    if (fileType !== FILE_TYPE_XLSX) {
      this.setState({
        file: null,
        errorNotification: 'Not an .xlsx File',
      });
      return setTimeout(() => {
        this.setState({
          errorNotification: null,
        });
      }, 3000);
    }

    this.setState({
      file,
    });
  };

  /*
   Handle Upload after Upload Button Clicked
   */
  handleUploadConfirm = (e) => {
    e.preventDefault();
    if (this.fileInputRef.current.files[0]) {
      const data = new FormData();
      data.append('excelFile', this.fileInputRef.current.files[0]);
      this.onRuleUploadConfirm(data);
    }
  };

  handleCancelUpload = (e) => {
    e.preventDefault();
    this.fileInputRef.current.value = '';
    this.setState({
      file: null,
    });
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose && onClose();
  };

  renderUploadContent = () => {
    return (
      <div className="upload-icon">
        <i className="fa fa-upload" aria-hidden="true">
          <SvgIcon name="upload" className="upload-icon--svg" />
        </i>
      </div>
    );
  };

  render() {
    const { dragOver, file, errorNotification } = this.state;

    return (
      <div className={CN}>
        <div className={cx('display-box', dragOver && 'drag-over', !!file && 'disabled')}>
          <div className="icon-text-box">
            {this.renderUploadContent()}
            <div className="upload-text">
              <h4>Choose File to Upload</h4>
            </div>
            {
              errorNotification && (
                <div className="error-notification">
                  <p>{errorNotification}</p>
                </div>
              )
            }
          </div>
          <input
            type="file"
            ref={this.fileInputRef}
            id="upload-file-input"
            className="upload-file-input"
            accept={FILE_TYPE_XLSX}
            onDrop={this.handleDrop}
            onDragEnter={this.handleDragEnter}
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            onChange={this.handleAddFile}
          />

        </div>
        {
          !!file && (
            <FileUploadConfirmationToast
              file={file}
              onCancel={this.handleCancelUpload}
              onConfirm={this.handleUploadConfirm}
            />
          )
        }
        {
          !file && (
            <div className={`${CN}__actions`}>
              <Button
                label={CANCEL_LABEL}
                className={`${CN}__cancel`}
                onClickHandler={this.onClose}
                style={BUTTON_STYLES.primary}
              />
            </div>
          )
        }
      </div>
    );
  }
}

FileUploader.propTypes = {
  onRuleUploadConfirm: func.isRequired,
};
