import React, { Component } from 'react';
import { Button } from '@nike/epic-react-ui';
import { generatePathWithParamsAndSearch } from '../../utils/url';
import { SvgIcon } from '../svg-icon/SvgIcon';

import '../../assets/icons/nike-logo.svg';
import './Header.scss';


const CN = 'header';

export default class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }


  goToHomePage = () => {
    const { history } = this.props;
    history.push(generatePathWithParamsAndSearch('/'));
  };

  logout = () => {
    const { oktaAuth } = this.props;
    oktaAuth.signOut('/');
  };

  render() {
    return (
      <header className={CN}>
        <span
          className={`${CN}__logo`}
          onClick={this.goToHomePage}
        >
          <SvgIcon name="nike-logo" className="header-logo" />
        </span>

        <div className={`${CN}__heading`}>Delivery Window</div>
        <div>
          <Button
            inverse
            extraSmall
            isDark
            theme={{}}
            onClick={this.logout}
          >
            Log Out
          </Button>
        </div>
        <div />
      </header>
    );
  }
}
