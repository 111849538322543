export const PA_1005 = '1005';
export const PA_1008 = '1008';
export const PA_1014 = '1014';
export const PA_1051 = '1051';
export const PA_0400 = '0400';
export const PA_1012 = '1012';
export const PA_1052 = '1052';
export const PA_1032 = '1032';
export const PA_1102 = '1102';
export const displayedPlantAccounts = [
  PA_1005,
  PA_1008,
  PA_1014,
  PA_1051,
  PA_0400,
  PA_1012,
  PA_1052,
  PA_1032,
  PA_1102,
];

export const displayedPlantAccountsLabels = {
  [PA_1005]: '1005 - Rebound',
  [PA_1008]: '1008 - Nike Adapt',
  [PA_1014]: '1014 - NALC-Memphis',
  [PA_1051]: '1051 - Nike USA-Shelby',
  [PA_0400]: '0400 - LDC - SD1 - Swoosh Direct 1',
  [PA_1012]: '1012 - Nike USA-FHR',
  [PA_1052]: '1052 - Nike NA-Shelby',
  [PA_1032]: '1032 - SD1',
  [PA_1102]: '1102 - Rebound',
};
