import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import ui from './ui.reducer';
import notifications from './notification.reducer';
import rules from './rules.reducer';

export default (history = {}) => combineReducers({
  router: connectRouter(history),
  ui,
  notifications,
  rules,
});
