/* eslint-disable */
import axios from 'axios';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import { routePath } from '../../utils/url';

const MOCK_URL = /^api$/;

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest"
  };
}

export class ApiAdapter {
  constructor({ api: { url } }) {
    this._url = url;
    this._authState = null;
  }

  setAuthState = (authState) =>  {
    this._authState = authState;
  }

  catchError = (error) => {
    return Promise.reject({ error });
  };

  composeUrl = path => {
    return MOCK_URL.test(this._url) ? routePath(`${this._url}${path}`) : `${this._url}${path}`;
  };

  get = (path, params = {}) => {
    const endpoint = this.composeUrl(path);

    const request = axios.get(endpoint, {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
      headers : this.header()
    })
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  getFile = (path, params = {}) => {
    const endpoint = this.composeUrl(path);

    const request = axios.get(endpoint,{
        responseType: 'blob',
        params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        },
        headers : this.header()
      })
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  post = (path, body = {}, params = {}) => {
    const apiPath = this.composeUrl(path);

    const config = isEmpty(params) ? {} : {
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    };

    config['headers'] = this.header();

    const request = axios.post(apiPath, body, config)
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  uploadfile = async (path, body = {}) => {
    const apiPath = this.composeUrl(path);

    const config = { responseType: 'blob' }

    config['headers'] = this.header();

    const request = await axios.post(apiPath, body, config)
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  put = (path, body = {}) => {
    const endpoint = this.composeUrl(path);

    const request = axios.put(endpoint, body, { headers : this.header()})
      .then(response => response)
      .catch(this.catchError);

    return request;
  };

  delete = (path, id) => {
    const endpoint = this.composeUrl(path + '/' + id);
    return axios.delete(endpoint, {
      headers : this.header()
    })
      .then(response => ({ "status" : response.status, id}))
      .catch(error => {
        return Promise.resolve({ id , status : "error" , error });
      });
  };

  patch = (path, body = {}, params = {}) => {
    const endpoint = this.composeUrl(path + '/' + params.id);
    const {ruleId, ...payload } = body;
    return axios.patch(endpoint, payload, {
      headers : this.header()
    })
    .then(response => response)
    .catch(error => {
      return Promise.resolve({ id : params.id , status : "error", error});
    });
};

  header = () => {
    if (this._authState && this._authState.accessToken) {
        const {tokenType, value} = this._authState.accessToken;
        return {
          'Authorization' : tokenType + ' ' + value
        };
    }
    return {};
  }

  

  throttleRequests = async (requestsToMake, maxParallelRequests = 100) => {
    const queue = [];
    const responses = []
    for (const requestToMake of requestsToMake) {
      const promise = requestToMake().then((data) => {
        queue.splice(queue.indexOf(promise), 1);
        return data;
      });
      queue.push(promise);
   if (queue.length >= maxParallelRequests) {
       const result  = await Promise.race(queue);
       responses.push(...result);
      }
    }
    const result = await Promise.all(queue);
    responses.push(...result);
    return responses;
  };  

  bulkRequestor = (payloadArray, api) => this.throttleRequests(payloadArray.map((data) => async () => await api(data)));

}
