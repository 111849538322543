import React, { memo } from 'react';
import cx from 'classnames';
import { bool, func, number } from 'prop-types';
import { Spinner } from '@nike/epic-react-ui';
import { Button, BUTTON_SIZES, BUTTON_STYLES } from '../button';
import { DELETE, UPDATE, UPLOAD } from '../../constants/rules';
import { CustomButton } from '../custom-button';

import '../../assets/icons/filter.svg';
import './ControlPanel.scss';

export const CN = 'fo-control-panel';

const isButtonDisabled = (disableButtons, buttonTypes) => disableButtons && disableButtons.filter(type => buttonTypes.includes(type)).length > 0;

const getToolTipMessage = (disableButtons, buttonType) => (isButtonDisabled(disableButtons, [buttonType]) ? buttonType + ' in Progress' : null);

const getProgressMsg = (disableButtons) => (disableButtons && disableButtons.length > 0 ? disableButtons.toString() + ' In Progress...' : null);


export const ControlPanel = memo(
  ({
    isDarkOn,
    addNewHandler,
    deleteHandler,
    editHandler,
    selectedCount,
    uploadHandler,
    downloadHandler,
    isSelectedAll,
    disableButtons,
    userInfo,
  }) => (
    <div className={CN}>
      <div className={`${CN}__counter`}>
        {`${selectedCount} ${selectedCount === 1 ? 'item' : 'items'} selected`}
      </div>

      {/* Existing functionality is comments and not removed
      <div
        onClick={editHandler}
        className={cx(`${CN}__controller`, `${CN}__btn`, `${CN}__select-all`, {
          [`${CN}--no-events`]: !isSelectedAll && !selectedCount,
          [`${CN}__btn--disabled`]: !isSelectedAll && !selectedCount,
        })}
      >
        Edit
      </div>

      <div className={`${CN}__divider-vertical`} />

      <DeleteBtn
        onClickHandler={deleteHandler}
        disabled={!isSelectedAll && !selectedCount}
      />

      <div className={`${CN}__divider-horizontal`} />
      */}
      {(userInfo && userInfo.writePerm)
      && (
        <Button
          className={cx(
            `${CN}__open-button`,
            `${CN}--apply`, {
              [`${CN}__open-button--dark-theme`]: isDarkOn,
            },
          )}
          style={BUTTON_STYLES.primary}
          onClickHandler={addNewHandler}
          label="Create new rule"
          size={BUTTON_SIZES.small}
        />
      )}

      {(userInfo && userInfo.writePerm)
      && (
        <Button
          className={cx(
            `${CN}__open-button`,
            `${CN}--apply`, {
              [`${CN}__open-button--dark-theme`]: isDarkOn,
            },
          )}
          disabled={(!isSelectedAll && !selectedCount) || isButtonDisabled(disableButtons, [UPDATE, DELETE])}
          style={BUTTON_STYLES.primary}
          onClickHandler={editHandler}
          label="Edit Rules"
          size={BUTTON_SIZES.small}
          toolTip={getToolTipMessage(disableButtons, DELETE) || getToolTipMessage(disableButtons, UPDATE)}
        />
      )}

      {(userInfo && userInfo.writePerm)
      && (
        <Button
          className={cx(
            `${CN}__open-button`,
            `${CN}--apply`, {
              [`${CN}__open-button--dark-theme`]: isDarkOn,
            },
          )}
          style={BUTTON_STYLES.primary}
          onClickHandler={deleteHandler}
          disabled={(!isSelectedAll && !selectedCount) || isButtonDisabled(disableButtons, [UPDATE, DELETE])}
          label="Delete Rules"
          size={BUTTON_SIZES.small}
          toolTip={getToolTipMessage(disableButtons, UPDATE) || getToolTipMessage(disableButtons, DELETE)}
        />
      )}

      { isButtonDisabled(disableButtons, [UPDATE, DELETE, UPLOAD]) && (
        <div
          className={cx(
            `${CN}__spinner`,
            `${CN}--apply`, {
              [`${CN}__open-button--dark-theme`]: isDarkOn,
            },
          )}
        >
          <span><Spinner color="black" /></span>
          <span>{getProgressMsg(disableButtons)}</span>
        </div>
      )}

      {(userInfo && userInfo.writePerm)
      && (
        <div className={cx(`${CN}__controller`, `${CN}__upload-btn`)}>

          <div className={`${CN}__divider-vertical`} />

        </div>
      )}

      {(userInfo && userInfo.writePerm)
      && (
        <CustomButton
          onClickHandler={uploadHandler}
          disabled={isButtonDisabled(disableButtons, [UPLOAD])}
          title="Upload Rules"
          name="upload"
          enableToolTip="Please select the button for bulk upload"
          disableToolTip="Upload in progress"
        />
      )}


      <CustomButton
        onClickHandler={downloadHandler}
        disabled={!selectedCount}
        title="Download Rules"
        name="download"
        disableToolTip="Please select rules for download, you can select all rules"
      />
    </div>
  ),
);

ControlPanel.propTypes = {
  isDarkOn: bool,
  selectedCount: number,
  deleteHandler: func.isRequired,
  editHandler: func.isRequired,
  uploadHandler: func.isRequired,
  downloadHandler: func.isRequired,
};

ControlPanel.defaultProps = {
  isDarkOn: false,
  selectedCount: 0,
};
