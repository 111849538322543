export const TEN = '10';
export const TWENTY = '20';
export const THIRTY = '30';
export const ALL = 'All';

export const displayedDivisionCodes = [
  TEN,
  TWENTY,
  THIRTY,
  ALL,
];

export const displayedDivisionCodesLabels = {
  [TEN]: '10',
  [TWENTY]: '20',
  [THIRTY]: '30',
  [ALL]: 'All',
};
