import React, { createContext, Component } from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';

export const themeContext = createContext();
const { Provider: ThemeProvider, Consumer: ThemeConsumer } = themeContext;

export { ThemeConsumer };

export class ThemeProviderWrapper extends Component {
  constructor(props) {
    super(props);

    let val;
    try {
      val = !!JSON.parse(localStorage.getItem('isDarkThemeOn'));
    } catch (e) {
      val = false;
      localStorage.setItem('isDarkThemeOn', JSON.stringify(val));
    }

    this.state = {
      isDarkOn: val,
    };
  }

  themeSwitcher = () => {
    this.setState((state) => {
      localStorage.setItem('isDarkThemeOn', JSON.stringify(!state.isDarkOn));

      return { isDarkOn: !state.isDarkOn };
    });
  };

  render() {
    const { isDarkOn } = this.state;
    const { children } = this.props;

    return (
      <ThemeProvider value={{
        isDarkOn: isDarkOn,
        themeSwitcher: this.themeSwitcher,
      }}
      >
        {children}
      </ThemeProvider>
    );
  }
}

ThemeProviderWrapper.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]).isRequired,
};
