import React, { Component, Fragment } from 'react';
import { object, array, bool, func } from 'prop-types';
import { ErrorBoundary } from '../../components/error-boundary';
import { Table } from '../../components/table';
import { ControlPanel } from '../../components/control-panel';
import { rulesTableFields, modes, DELETE } from '../../constants/rules';
import { AddEditModal } from '../../components/add-edit-modal';
import { RULES_LIST_LOADER } from '../../constants/loaders';
import { AnimatedLoader } from '../../components/animated-loader';
import { DeleteModal } from '../../components/delete-modal';
import { UploadModal } from '../../components/upload-modal';

import './DeliveryWindow.scss';

const CN = 'fo-rules-page';

const isFilterSelected = (filters) => {
  return Object.keys(filters).filter(key => filters[key] && filters[key].length > 0).length > 0;
};

export class DeliveryWindow extends Component {
  constructor(props) {
    super(props);
    this.state = { displayDelete: false };
  }

  componentDidMount() {
    const { actions: { getRulesList } } = this.props;

    getRulesList && getRulesList({ filter: 'getAll(true)', mediaType: 'JSON' });
  }

  downloadRules = () => {
    const { isSelectedAll, selectedRow, selectedIds, actions: { downloadRules }, data, selectedFilter } = this.props;
    const neededIds = selectedIds.length ? selectedIds : [selectedRow];
    downloadRules && downloadRules({ mediaType: 'MS-EXCEL' }, isSelectedAll ? (!isFilterSelected(selectedFilter) ? null : data.map(item => item.id)) : neededIds);
  };

  onDeleteRules = () => {
    this.setState({ displayDelete: true });
    const { actions: { onDelete } } = this.props;
    onDelete && onDelete();
  };

  deleteRulesConfirm = () => {
    this.setState({ displayDelete: false });
    const { isSelectedAll, selectedRow, selectedIds, data, actions: { deleteRules, disableButton } } = this.props;
    disableButton(DELETE);
    const neededIds = selectedIds.length ? selectedIds : [selectedRow];
    deleteRules && deleteRules(isSelectedAll ? data.map(item => item.id) : neededIds);
  };

  resetDeleteModal = () => {
    const { actions: { onResetFlags } } = this.props;
    this.setState({ displayDelete: false });
    onResetFlags();
  }

  onRowSelect = (item) => {
    const { actions: { onItemToggle } } = this.props;

    onItemToggle && onItemToggle(item);
  };

  onRowClick = (item) => {
    const { actions: { onItemSelect } } = this.props;

    onItemSelect && onItemSelect(item);
  };

  onFilterClick = (item) => {
    const { actions: { onFilterSelect } } = this.props;

    onFilterSelect && onFilterSelect(item);
  };

  addNewHandler = () => {
    const { actions: { onCreate } } = this.props;

    onCreate && onCreate();
  };

  editHandler = () => {
    const { actions: { onEdit } } = this.props;

    onEdit && onEdit();
  };

  onUploadRules = () => {
    const { actions: { onUpload } } = this.props;

    onUpload && onUpload();
  };

  onUploadRulesConfirm = (file) => {
    const { actions: { uploadRules, onResetFlags } } = this.props;

    onResetFlags && onResetFlags();
    uploadRules && uploadRules(file);
  };

  renderModal = () => {
    const { userInfo, mode, actions: { onResetFlags } } = this.props;
    if (!mode || (mode !== modes.ADD && mode !== modes.EDIT)) return null;

    return (
      <AddEditModal
        mode={mode}
        onClose={onResetFlags}
        userInfo={userInfo}
      />
    );
  } ;

  renderDeleteModal = () => {
    const { mode, selectedIds, isSelectedAll, data } = this.props;
    const { displayDelete } = this.state;

    if (!mode || mode !== modes.DELETE || !displayDelete) return null;

    return (
      <DeleteModal
        onApply={this.deleteRulesConfirm}
        onClose={this.resetDeleteModal}
        affectedCount={isSelectedAll ? data.length : selectedIds.length}
        isOpen
      />
    );
  };

  renderUploadModal = () => {
    const { actions: { onResetFlags }, mode } = this.props;
    if (!mode || mode !== modes.UPLOAD) return null;

    return (
      <UploadModal
        onApply={this.onUploadRulesConfirm}
        onClose={onResetFlags}
        isOpen
      />
    );
  };

  renderTable = () => {
    const {
      data,
      selectedIds,
      isSelectedAll,
      filterValues,
      selectedFilter,
      actions: { onToggleSelectAll, onUpdateSelectedFilter },
    } = this.props;

    return (
      <ErrorBoundary>
        <Table
          rowFields={rulesTableFields}
          data={data}
          originalData={data}
          filterValues={filterValues}
          selectedFilter={selectedFilter}
          selectedIds={selectedIds}
          onRowSelect={this.onRowSelect}
          onRowClick={this.onRowClick}
          isSelectedAll={isSelectedAll}
          onToggleSelectAll={onToggleSelectAll}
          onUpdateSelectedFilter={onUpdateSelectedFilter}
          showFilter
        />
      </ErrorBoundary>
    );
  };

  render() {
    const {
      data,
      selectedIds,
      isSelectedAll,
      loadersList,
      disableButtons,
      userInfo,
    } = this.props;
    const selectedCount = isSelectedAll ? data.length : selectedIds.length;
    const { isDarkOn } = this.context;

    return (
      <Fragment>
        <ErrorBoundary>
          <div className={`${CN}__selection-panel`}>
            <ControlPanel
              selectedCount={selectedCount}
              editHandler={this.editHandler}
              addNewHandler={this.addNewHandler}
              deleteHandler={this.onDeleteRules}
              isSelectedAll={isSelectedAll}
              modalHandler={this.openOverrideScreen}
              isDarkOn={isDarkOn}
              downloadHandler={this.downloadRules}
              uploadHandler={this.onUploadRules}
              disableButtons={disableButtons}
              userInfo={userInfo}
            />
          </div>
        </ErrorBoundary>
        {loadersList.includes(RULES_LIST_LOADER) && <AnimatedLoader /> || this.renderTable()}
        {this.renderModal()}
        {this.renderDeleteModal()}
        {this.renderUploadModal()}
      </Fragment>
    );
  }
}

DeliveryWindow.propTypes = {
  data: array.isRequired,
  originalData: array.isRequired, // eslint-disable-line
  actions: object,
  selectedIds: array,
  isSelectedAll: bool,
  loadersList: array,
  onUpdateSelectedFilter: func,
};

DeliveryWindow.defaultProps = {
  selectedIds: [],
  isSelectedAll: false,
  loadersList: [],
};
