import {
  ADD_LOADER,
  REMOVE_LOADER,
} from '../action-types/ui.type';

const initialState = {
  loadersList: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_LOADER: {
      const { payload } = action;
      const { loadersList } = state;

      return {
        ...state,
        loadersList: [...loadersList, payload],
      };
    }

    case REMOVE_LOADER: {
      const { payload } = action;
      const { loadersList } = state;

      return {
        ...state,
        loadersList: loadersList.filter(loader => loader !== payload),
      };
    }

    default:
      return state;
  }
};
