import { PLANT_ACCOUNT, PLUS_END_DATES, PLUS_START_DAYS, SHIP_TO, SOLD_TO, DIVISION, LAUNCH_CODE, CHANNEL, SHIP_TO_NAME, CITY, STATE } from './rules';

export const HEADER_HEIGHT = 38;
export const ROW_HEIGHT = 28;
export const DUMMY_ROWS_COUNT = 6;

export const DUMMY_ROW = {
  [PLANT_ACCOUNT]: '',
  [SOLD_TO]: '',
  [SHIP_TO]: '',
  [DIVISION]: '',
  [LAUNCH_CODE]: '',
  [PLUS_START_DAYS]: '',
  [PLUS_END_DATES]: '',
  [CHANNEL]: '',
  [SHIP_TO_NAME]: '',
  [CITY]: '',
  [STATE]: '',
};
