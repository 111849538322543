export const DELIVERY_WINDOW_CALCULATION = 'DELIVERY_WINDOW_CALCULATION';

export const DELETE = 'Delete';
export const UPDATE = 'Update';
export const UPLOAD = 'Upload';

export const SOLD_TO = 'soldTo';
export const PLANT_ACCOUNT = 'plantAccount';
export const SHIP_TO = 'shipTo';
export const PLUS_START_DAYS = 'startOffsetDays';
export const PLUS_END_DATES = 'endOffsetDays';
export const PRIORITY = 'priority';
export const NAME = 'name';
export const ENABLED = 'enabled';
export const RULE_TYPE = 'ruleType';
export const COMMENT = 'comment';
export const DIVISION = 'division';
export const LAUNCH_CODE = 'launchCode';
export const CHANNEL = 'channel';
export const CITY = 'city';
export const STATE = 'state';
export const SHIP_TO_NAME = 'shipToName';
export const FILTER_FIELDS = [PLANT_ACCOUNT, SOLD_TO, SHIP_TO, DIVISION, LAUNCH_CODE, CITY, STATE, SHIP_TO_NAME, CHANNEL];

export const DELIVERY_WINDOW_WRITE_AUTH = [
  'App.DeliveryWindow.Test.Users',
  'App.DeliveryWindow.Prod.Users',
];


export const defaultDataStructure = {
  [PLANT_ACCOUNT]: {
    type: 'text',
    value: '',
  },
  [SOLD_TO]: {
    type: 'text',
    value: '',
  },
  [SHIP_TO]: {
    type: 'text',
    value: '',
  },
  [DIVISION]: {
    type: 'text',
    value: '',
  },
  [LAUNCH_CODE]: {
    type: 'text',
    value: '',
  },
  [PLUS_START_DAYS]: {
    type: 'number',
    value: 0,
  },
  [PLUS_END_DATES]: {
    type: 'number',
    value: 0,
  },
  [PRIORITY]: {
    type: 'number',
    value: 0,
  },
  [NAME]: {
    type: 'text',
    value: '',
  },
  [ENABLED]: {
    type: 'boolean',
    value: true,
  },
  [RULE_TYPE]: {
    type: 'text',
    value: DELIVERY_WINDOW_CALCULATION,
  },
  [COMMENT]: {
    type: 'text',
    value: '',
  },
  [CITY]: {
    type: 'text',
    value: '',
  },
  [STATE]: {
    type: 'text',
    value: '',
  },
  [SHIP_TO_NAME]: {
    type: 'text',
    value: '',
  },
  [CHANNEL]: {
    type: 'text',
    value: '',
  },
};

export const modes = {
  EDIT: 'EDIT',
  ADD: 'ADD',
  DELETE: 'DELETE',
  UPLOAD: 'UPLOAD',
};

export const rulesTableFields = [
  PLANT_ACCOUNT,
  SOLD_TO,
  CHANNEL,
  SHIP_TO,
  SHIP_TO_NAME,
  CITY,
  STATE,
  DIVISION,
  LAUNCH_CODE,
  PLUS_START_DAYS,
  PLUS_END_DATES,
];

export const editableFields = [PLUS_START_DAYS, PLUS_END_DATES, CHANNEL, SHIP_TO_NAME, CITY, STATE];

export const optionalFields = [CHANNEL, SHIP_TO_NAME, CITY, STATE, COMMENT];

export const tableTitles = {
  [SOLD_TO]: 'Sold To',
  [PLANT_ACCOUNT]: 'Plant Account',
  [SHIP_TO]: 'Ship To',
  [DIVISION]: 'Division (10, 20, 30)',
  [LAUNCH_CODE]: 'Launch Code (Y/N)',
  [PLUS_START_DAYS]: 'START OFFSET DAYS X',
  [PLUS_END_DATES]: 'END OFFSET DAYS Y',
  [CHANNEL]: 'SOLD TO CONTACT PERSON',
  [SHIP_TO_NAME]: 'SHIP TO NAME',
  [CITY]: 'SHIP TO CITY',
  [STATE]: 'SHIP TO STATE',
};
