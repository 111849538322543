import React, { PureComponent } from 'react';
import { object, array, string, bool } from 'prop-types';
import cx from 'classnames';

import { tableTitles } from '../../../constants/rules';
import { SortField } from '../sort-field';
import { ErrorBoundary } from '../../error-boundary';
import './HeaderRow.scss';
import { Checkbox } from '../../checkbox';

export const CN = 'fo-table__header';

export class HeaderRow extends PureComponent {
  onCheckBoxClick = () => {
    const { onCheckBoxClick } = this.props;
    onCheckBoxClick && onCheckBoxClick();
  };

  onFilter = (key, values) => {
    const { onFilterSelect } = this.props;
    onFilterSelect({ key, values });
  };

  render() {
    const {
      style,
      titles,
      isDarkOn,
      withBackground,
      hidePlaceholder,
      className,
      isChecked,
      filterValues,
      showFilter,
      selectedFilter,
    } = this.props;

    return (
      <div className={cx(CN, className, { [`${CN}--dark-theme`]: isDarkOn })} style={style}>
        {
          !hidePlaceholder && (
            <Checkbox
              className={cx(
                `${CN}__checkbox-wrapper`, {
                  [`${CN}__checkbox-wrapper--dark-theme`]: isDarkOn,
                },
              )}
              checked={isChecked}
              onCheck={this.onCheckBoxClick}
            />
          )
        }

        {
          titles.map(key => {
            return (
              <ErrorBoundary key={key}>
                <SortField
                  key={key}
                  label={tableTitles[key]}
                  withBackground={withBackground}
                  filterValues={showFilter && filterValues ? filterValues[key] : []}
                  onFilterSelect={(val) => this.onFilter(key, val)}
                  selectedFilter={(selectedFilter && selectedFilter[key]) || []}
                  showFilter={showFilter}
                />
              </ErrorBoundary>
            );
          })
        }
      </div>
    );
  }
}

HeaderRow.propTypes = {
  titles: array,
  style: object,
  withBackground: bool,
  hidePlaceholder: bool,
  className: string,
  isChecked: bool,
};

HeaderRow.defaultProps = {
  withBackground: false,
  hidePlaceholder: false,
  className: '',
  isChecked: false,
};
