import { ApiAdapter } from './api'; // eslint-disable-line
import { RulesService } from './api/rules.service';
import defaultConfig from '../../config/base.config';

export const initConfig = (config) => {
  if (!config.api || !Object.keys(config.api).length) {
    config.api = {
      url: '',
    };
  }
  if (!config.okta || !Object.keys(config.okta).length) {
    config.okta = {
      'issuer': '',
      'clientId': '',
      'redirectUri': '',
      'scopes': '',
      'pkce': true,
    };
  } else {
    config.okta = {
      ...config.okta,
      'redirectUri': window.location.origin + '/login/callback',
    };
  }

  return config;
};

export const readConfig = () => {
  let config;

  if (window.__CONFIG__) {
    config = JSON.parse(JSON.stringify(window.__CONFIG__));
    delete window.__CONFIG__;
  } else {
    config = defaultConfig;
  }

  return initConfig(config);
};

const config = readConfig();

const apiAdapter = new ApiAdapter(config);
const rulesService = new RulesService(apiAdapter, config);

export default {
  config,
  apiAdapter,
  rulesService,
};
