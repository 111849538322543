import queryString from 'query-string';
import { generatePath } from 'react-router';
import PathToRegexp, { compile } from 'path-to-regexp';
// import { name as appName } from '../../package.json';

export const ROUTER_PREFIX = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? '' : '';

export const routePath = path => `${ROUTER_PREFIX}/${path}`;

export const generatePathWithParamsAndSearch = (path, search = '') => {
  if (path[0] === '/') {
    path = path.slice(1);
  }

  let newPath = `${generatePath(path)}${search}`;

  if (newPath[0] === '/') {
    newPath = newPath.slice(1);
  }

  return routePath(newPath);
};

// todo: all these 2 functions must be refactored++
export const generateUrl = (pathname, selectedGeo) => {
  const ROUTE = '/:appName*/:selectedGeo(us|global|emea)/:path*';
  const definePath = compile(ROUTE);
  const routeComponents = PathToRegexp(ROUTE).exec(pathname);
  const [fullPath, appName = '', oldGeo, other] = routeComponents || []; //eslint-disable-line

  let subPaths = null;
  if (routeComponents && selectedGeo) {
    subPaths = other.split('/');
  }

  const newComponents = {
    selectedGeo: selectedGeo.toLowerCase(),
    path: subPaths,
  };

  if (appName) {
    newComponents.appName = appName;
  }

  return definePath(newComponents);
};

export const parseUrl = (urlQuery, arrayFormat = 'index') => queryString.parse(urlQuery, { arrayFormat });

export const buildUrlWithQueryString = (query, arrayFormat = 'index', fallback = location.pathname) => {
  const updatedUrl = queryString.stringify(query, { arrayFormat });

  return updatedUrl ? `?${updatedUrl}` : fallback;
};
