/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { withRouter } from 'react-router';
import AppInternal from './AppInternal';
import services from '../services';
import { themeContext } from '../context/ThemeContext';

import './App.scss';


class App extends Component {

  static contextType = themeContext;

  render() {
    const {
      config: {
        okta,
      },
    } = services;

    // PKCE authorization flow
    const oktaAuth = new OktaAuth(okta);

    return (
      <Security oktaAuth={oktaAuth}> <AppInternal {...this.props} /> </Security>
    );
  }
}

export default withRouter(App);
