import React, { PureComponent } from 'react';
import { object, number, bool, array, string } from 'prop-types';
import cx from 'classnames';
import isNil from 'lodash/isNil';

import { TooltipWrapper } from '../../TooltipWrapper';
import { defaultDataStructure, rulesTableFields, editableFields, modes, PLANT_ACCOUNT, LAUNCH_CODE, DIVISION, SOLD_TO, SHIP_TO } from '../../../constants/rules';
import {
  displayedPlantAccounts,
  displayedPlantAccountsLabels,
} from '../../../constants/plant-account-constants';
import {
  displayedLaunchCodes,
  displayedLaunchCodesLabels,
} from '../../../constants/launch-codes-constants';
import {
  displayedDivisionCodes,
  displayedDivisionCodesLabels,
} from '../../../constants/division-constants';
import { TextField } from '../../text-field';
import { Dropdown } from '../../dropdown';

import '../../../assets/icons/info.svg';
import './EditableTableRow.scss';

export const CN = 'editable-table-row';

const ROW_ORDER = ['even', 'odd'];

export class EditableTableRow extends PureComponent {
  constructor(props) {
    super(props);

    this.editableFieldsKeys = props.mode === modes.ADD ? rulesTableFields : editableFields;
  }

  onFieldChange = (fieldKey) => {
    const { onChange, data, errorRows } = this.props;

    return (newValue) => {
      const errorObject = this.validateForm(fieldKey, newValue);
      const newEditableErrorData = {
        ...errorRows,
        [fieldKey]: errorObject.errorMessage,
      };
      const newEditableData = {
        ...data,
        [fieldKey]: newValue,
      };

      onChange && onChange(newEditableData, newEditableErrorData);
    };
  };

  validateForm = (fieldName, fieldValue) => {
    const errorObj = { error: false, errorMessage: '' };
    switch (fieldName) {
      case SOLD_TO: {
        if (fieldValue && !/^\d{10}$/.test(fieldValue)) {
          errorObj.error = true;
          errorObj.errorMessage = 'Length should be 10';
        }
        return errorObj;
      }
      case SHIP_TO: {
        if (fieldValue && !/^\d{10}$/.test(fieldValue)) {
          errorObj.error = true;
          errorObj.errorMessage = 'Length should be 10';
        }
        return errorObj;
      }
      default: {
        return errorObj;
      }
    }
  }

  onPlantAccountChange = (newValue) => {
    const { onChange, data, errorRows } = this.props;

    const newEditableData = {
      ...data,
      [PLANT_ACCOUNT]: newValue[0],
    };

    onChange && onChange(newEditableData, errorRows);
  };

  onLaunchCodeChange = (newValue) => {
    const { onChange, data, errorRows } = this.props;

    const newEditableData = {
      ...data,
      [LAUNCH_CODE]: newValue[0],
    };

    onChange && onChange(newEditableData, errorRows);
  };

  onDivisionChange = (newValue) => {
    const { onChange, data, errorRows } = this.props;

    const newEditableData = {
      ...data,
      [DIVISION]: newValue[0],
    };

    onChange && onChange(newEditableData, errorRows);
  };

  renderFieldContent = (field, data, errorRows) => {
    const renderContent = () => {
      return (
        <div
          className={`${CN}__value`}
        >
          {isNil(data[field]) ? <span className={`${CN}--no-data`} /> : data[field]}
        </div>
      );
    };

    if (this.editableFieldsKeys.includes(field)) {

      switch (field) {
        case PLANT_ACCOUNT: {
          return (
            <Dropdown
              className={`${CN}__dropdown`}
              defaultOption="Select Plant Account"
              selected={[data[field]]}
              onSelect={this.onPlantAccountChange}
              options={displayedPlantAccounts}
              labels={displayedPlantAccountsLabels}
            />
          );
        }
        case LAUNCH_CODE: {
          return (
            <Dropdown
              className={`${CN}__dropdown`}
              defaultOption="Select Launch Code"
              selected={[data[field]]}
              onSelect={this.onLaunchCodeChange}
              options={displayedLaunchCodes}
              labels={displayedLaunchCodesLabels}
            />
          );
        }
        case DIVISION: {
          return (
            <Dropdown
              className={`${CN}__dropdown`}
              defaultOption="Select Division"
              selected={[data[field]]}
              onSelect={this.onDivisionChange}
              options={displayedDivisionCodes}
              labels={displayedDivisionCodesLabels}
            />
          ); }
        default: {
          return (
            <TextField
              name={`${data.id ? data.id : 'creatable'}-${field}`}
              type={defaultDataStructure[field].type}
              value={data[field]}
              onChange={this.onFieldChange(field)}
              className={`${CN}__input`}
              errorMessage={errorRows ? errorRows[field] : ''}
            />
          ); }

      }
    }

    // no need in tooltip if val is falsy
    return (!data[field]) ? renderContent() : (
      <TooltipWrapper val={data[field]}>
        {renderContent()}
      </TooltipWrapper>
    );
  };

  render() {
    const {
      data,
      style,
      index,
      isDarkOn, //eslint-disable-line
      isChecked,
      loading,
      disabledHighlighting,
      hasError,
      rowFields,
      className,
      onRowClick,
      errorRows,
    } = this.props;
    return (
      <div
        className={cx(CN, className, {
          [`${CN}--selected`]: !disabledHighlighting && isChecked && !loading,
          [`${CN}--has-error`]: hasError,
          [`${CN}--is-clickable`]: !!onRowClick,
        })}
        style={style}
      >
        {
          rowFields.map((field) => (
            <div
              key={field}
              className={cx(
                `${CN}__item`,
                `${CN}__item__${field}`,
                `${CN}__item--${ROW_ORDER[index % 2]}`,
                {
                  [`${CN}__item--has-error`]: hasError,
                  [`${CN}__item--unchecked`]: disabledHighlighting && !isChecked,
                },
              )}
            >
              {this.renderFieldContent(field, data, errorRows)}
            </div>
          ))
        }
      </div>
    );
  }
}

EditableTableRow.propTypes = {
  data: object,
  style: object,
  index: number,
  isDarkOn: bool,
  loading: bool,
  isChecked: bool,
  disabledHighlighting: bool,
  hasError: bool,
  rowFields: array,
  className: string,
};

EditableTableRow.defaultProps = {
  isDarkOn: false,
  loading: false,
  isChecked: false,
  disabledHighlighting: false,
  hasError: false,
  rowFields: [],
  className: '',
};
