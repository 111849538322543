export const Y = 'Y';
export const N = 'N';

export const displayedLaunchCodes = [
  Y,
  N,
];

export const displayedLaunchCodesLabels = {
  [Y]: 'Y',
  [N]: 'N',
};
