import {
  SHOW_SUCCESS_MESSAGE,
  SHOW_ERROR_MESSAGE,
  ON_NOTIFICATION_RESET,
  REMOVE_LOADER,
  ADD_LOADER,
  SHOW_WARNING_MESSAGE,
} from '../action-types/ui.type';
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS, NOTIFICATION_TYPE_WARNING } from '../constants/notification';

export const addLoader = (payload) => ({ type: ADD_LOADER, payload });

export const removeLoader = (payload) => ({ type: REMOVE_LOADER, payload });

export const showMessage = (
  payload = {},
  notificationType = NOTIFICATION_TYPE_SUCCESS,
) => ({
  type: notificationType === NOTIFICATION_TYPE_ERROR ? SHOW_ERROR_MESSAGE : (notificationType === NOTIFICATION_TYPE_WARNING ? SHOW_WARNING_MESSAGE : SHOW_SUCCESS_MESSAGE),
  payload: { ...payload },
});

export const resetNotificationData = () => ({ type: ON_NOTIFICATION_RESET });
