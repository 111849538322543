import React from 'react';
import { string, element, oneOfType } from 'prop-types';
import { SvgIcon } from '../svg-icon/SvgIcon';

import '../../assets/icons/warning.svg';
import './WarningMessage.scss';

const CN = 'fo-warning';

export const WarningMessage = ({ msg, className }) => (
  <div className={`${CN} ${className}`}>
    <SvgIcon name="warning" className={`${CN}__icon`} />
    <div className={`${CN}__text`}>{msg}</div>
  </div>
);

WarningMessage.propTypes = {
  msg: oneOfType([
    string,
    element,
  ]),
  className: string,
};

WarningMessage.defaultProps = {
  msg: '',
  className: '',
};
